<table [class]="tableClass">
  <thead
    *ngIf="showHeader"
    adsp-table-thead
    [columns]="columns"
    [dataSource]="dataSource"
    [selected]="selected"
    [rowIdentity]="rowIdentity"
    (checkboxToggle)="checkboxToggleHeader($event)"
  ></thead>

  <tbody
    adsp-table-tbody
    [emptyMessage]="emptyMessage"
    [columns]="columns"
    [dataSource]="dataSource"
    [selected]="selected"
    [rowIdentity]="rowIdentity"
    [rowDetail]="rowDetail"
    [rowDetailBorder]="rowDetailBorder"
    [ngClass]="isRowClickWired ? 'cursor-pointer' : 'cursor-default'"
    (rowClick)="rowClick.emit($event)"
    (checkboxToggle)="checkboxToggleRow($event)"
  ></tbody>
</table>

<agid-pager
  *ngIf="showPager && (dataSource.rows$ | async)?.length"
  position="center"
  [page]="dataSource.tablePage.pageNumber"
  [size]="dataSource.tablePage.size"
  [count]="dataSource.tablePage.totalElements"
  (pagerChange)="pagerChange($event)"
></agid-pager>
