import { Component, Input } from '@angular/core';
import { BaseInputComponent } from '../base-input/base-input.component';
import { DividerInput } from '../../../models';

@Component({
  selector: 'app-divider-input',
  styleUrls: ['./divider-input.component.scss'],
  template: `
    <!-- <app-divider
    [marginBottom]="control.marginBottom"
    [marginTop]="control.marginTop"
  ></app-divider> -->
    <span class="label_underline d-block pb-2 mb-4 font-size-18">
      {{ control.label }}
    </span>
  `,
})
export class DividerInputComponent extends BaseInputComponent {
  @Input() control: DividerInput;
}
