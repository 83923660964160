import { Injectable } from '@angular/core';
import { ApiClient } from '@eng-ds/api-client';
import { TranslateService as EngTranslateService } from '@eng-ds/translate';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, takeUntil, tap } from 'rxjs/operators';
import { IT } from 'src/assets/i18n/IT';
import { AutoUnsubscribe } from '../components';

interface Language {
  nome: string;
  codice: string;
  abilitata: boolean;
  idLingua: number;
}

declare const Adsp: any;

const MODULE = 'root';

interface JsonTranslate {
  [x: string]: string;
}

/**
 * Questo modulo non supporta il multilingua (il file delle traduzioni viene preso dal progetto e non dal server),
 * ma poichè il json degli errori vengono presi dal server (quindi multilingua), c'è la necessita di un merge tra i due file
 * che viene eseguito in questo service
 */

@Injectable()
export class TranslateService extends AutoUnsubscribe {
  constructor(
    private apiClient: ApiClient,
    private translateService: EngTranslateService
  ) {
    super();
    this._subscribeOnLanguageChange()
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  // fetch delle traduzioni
  private _fetchTranslate(code: string): Observable<JsonTranslate> {
    return this.apiClient
      .request<JsonTranslate>('getTranslate', null, null, {
        languageCode: code.toUpperCase(),
        module: MODULE,
      })
      .pipe(
        tap((result: JsonTranslate) => {
          // set delle traduzioni
          const translations = { ...IT, ...result };
          this.translateService.setTranslation(code, translations);
        }),
        catchError((e) => {
          return of({});
        })
      );
  }

  private _subscribeOnLanguageChange(): Observable<any> {
    return (Adsp.events.header.activeLanguage$ as Observable<Language>).pipe(
      switchMap((lang) =>
        // fetch del json delle traduzioni

        this._fetchTranslate(lang.codice).pipe(
          tap(() => {
            // set del codice lingua sulle traduzioni
            this.translateService.use(lang.codice);
          })
        )
      )
    );
  }
}
