import { APP_BASE_HREF } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CodiceVerticaleResolver } from './core/services/codiceVerticale.resolver';
import { EmptyRouteComponent } from './shared/components';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/pages.module').then((m) => m.PagesModule),
    resolve: { codiceVerticale: CodiceVerticaleResolver },
  },
  { path: '**', component: EmptyRouteComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,  {scrollPositionRestoration: 'top'})],
  exports: [RouterModule],
  providers: [{ provide: APP_BASE_HREF, useValue: '/amm' }],
})
export class AppRoutingModule {}
