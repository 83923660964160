<!-- Modale da validare -->
<agid-modal position="center" [open]="open" (closed)="closeModale()">
  <agid-modal-header class="pb-3 modal-header" close-icon="true">
    <span class="align-middle modal-title"
      >{{ "MGU_UTENTE_LIST_MODAL_DA_VALIDARE_TITLE" | translate }}
    </span>
  </agid-modal-header>

  <agid-modal-body class="pt-1">
    <div class="row justify-content-center mt-4">
      <div
        *ngIf="errorMessage"
        class="w-100 alert alert-danger font-size-14"
        role="alert"
      >
        {{ errorMessage }}
      </div>
    </div>
    <div class="row py-3 ml-0">
      <span class="font-size-16 mb-2">{{
        "MGU_UTENTE_LIST_MODAL_DA_VALIDARE_TEXT_1" | translate
      }}</span
      ><!-- <span *ngIf="utenteSel">{{
        utenteSel?.nome + " " + utenteSel?.cognome
      }}</span> -->
      <span class="font-size-14">{{
        "MGU_UTENTE_LIST_MODAL_DA_VALIDARE_TEXT_2" | translate
      }}</span>
    </div>
  </agid-modal-body>

  <agid-modal-footer>
    <agid-button
      class="text-center danger float-right ml-3"
      size="small"
      [color]="'danger'"
      (click)="validaSUA(TIPO_STATO_SUA.NEGATO)"
    >
      {{ "UTILS_CHANGE_STATO_SUA_NEGATO" | translate }}
    </agid-button>
    <agid-button
      class="text-center success float-right"
      size="small"
      color="success"
      (click)="validaSUA(TIPO_STATO_SUA.APPROVATO)"
    >
      {{ "UTILS_CHANGE_STATO_SUA_APPROVATO" | translate }}
    </agid-button>
  </agid-modal-footer>
</agid-modal>
