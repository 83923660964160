<agid-modal
  (closed)="annullaIncardinamentoOrganizzazione()"
  [open]="open"
  [scrollable]="elementSelected?.length >= 4"
  class="dialog"
  position="center"
  size="large"
>
  <agid-modal-header class="pb-4 bordo align-items-baseline" close-icon="true">
    <span class="modal-title">
      {{"MGU_INCARDINAMENTO_MODAL_TITLE" | translate}}
    </span>
  </agid-modal-header>

  <agid-modal-body>
    <div class="container-fluid font-size-16">
      <div *ngFor="let selectedItem of elementSelected; let i = index; let last = last" class="row m-0">
        <div *ngIf="selectedItem?.denominazione" class="col-12 mb-3 d-inline-flex font-weight-bold primary-color riga">
          <span class="m-auto flex-fill align-self-center" style="-webkit-line-clamp: 3; -webkit-box-orient: vertical">
            {{ selectedItem?.denominazione }}
          </span>
        </div>
        <div class="col-12 m-auto riga">
          <div class="form-group p-2 m-0">
            <app-form
              *ngIf="checkFormExists(selectedItem?.uuid)"
              [form]="formGroupIncardinamento?.get(selectedItem?.uuid)"
            ></app-form>
          </div>
        </div>
        <div *ngIf="!last" class="card-separator mt-3">&nbsp;</div>
      </div>
    </div>
  </agid-modal-body>

  <agid-modal-footer>
    <agid-button
      (click)="salvaIncardinamentoOrganizzazione()"
      [disabled]="!formGroupIncardinamento?.valid"
      class="m-auto"
      primary>
      {{ "FORM_SAVE" | translate }}
    </agid-button>
  </agid-modal-footer>
</agid-modal>
