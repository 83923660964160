import { BaseComponent } from '../../../core/components';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { catchError, takeUntil } from 'rxjs/operators';
import { SoggettoFisicoEnte, Utente } from '../../../pages/utenti/model';
import { UtentiService } from '../../../pages/utenti/services';
import { I18nService } from '@eng-ds/translate';
import { throwError } from 'rxjs';

declare const AgidWebComponents: any;
declare const Adsp: any;

@Component({
  selector: 'adsp-mgu-dati-dipendente',
  templateUrl: './dati-dipendente.component.html',
  styleUrls: ['./dati-dipendente.component.css'],
})
export class DatiDipendenteComponent extends BaseComponent {
  @Input() editRecapiti = false;
  @Input() editIncardinamento = true;
  @Input() utente: Utente;
  @Output() updateUtente: EventEmitter<any> = new EventEmitter();
  errorMessage = '';

  selectedSoggettoFisico: SoggettoFisicoEnte;
  openDialog = false;

  constructor(protected i18n: I18nService, private service: UtentiService) {
    super(i18n);
  }

  get soggettoFisicoEnte(): SoggettoFisicoEnte {
    return this.utente.soggettoFisicoEnte ?? ({} as SoggettoFisicoEnte);
  }

  onEditEmployeeData(): void {
    const {
      dataFineValidita,
      dataInizioValidita,
      email,
      pec,
      telefono,
      uuid,
      uuidSoggettoFisico,
      abilitatoRubrica,
      abilitatoBackoffice,
      notificaControlloAccessi,
      categoria,
      funzioneCodice,
      hr,
    } = this.soggettoFisicoEnte;
    this.selectedSoggettoFisico = {
      dataFineValidita,
      dataInizioValidita,
      email,
      pec,
      telefono,
      uuid,
      uuidSoggettoFisico,
      abilitatoRubrica,
      abilitatoBackoffice,
      notificaControlloAccessi,
      categoria,
      funzioneCodice,
      hr,
    };
    this.openDialog = true;
    this.errorMessage = null;
  }

  onDialogCancelled(value: boolean): void {
    this.openDialog = false;
    delete this.selectedSoggettoFisico;
  }

  onDialogCompleted(soggettoFisicoEnte: SoggettoFisicoEnte): void {
    Adsp.loading.show();
    let obj;
    if (soggettoFisicoEnte.uuid) {
      obj = this.service.upsertContatto(soggettoFisicoEnte);
    } else {
      soggettoFisicoEnte.uuidSoggettoFisico = this.utente.uuid;
      obj = this.service.setContatto(soggettoFisicoEnte);
    }
    obj
      .pipe(
        catchError((err) => {
          this.errorMessage = this._controlTranslate(err.error);
          return throwError(err);
        })
      )
      .subscribe(() => this._afterSaveContatto());
    Adsp.loading.hide();
  }

  private _afterSaveContatto() {
    AgidWebComponents.notifications.success(
      this.i18n.translate('MGU_DATI_DIPENDENTE_UPDATE_SUCCESS_TITLE'),
      this.i18n.translate('MGU_DATI_DIPENDENTE_UPDATE_SUCCESS_TEXT')
    );
    this.service
      .getUtente(this.utente.uuid)
      .pipe(
        catchError((err) => {
          this._handleErrorMessage(err.error, true);
          return throwError(err);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((resp: Utente) => {
        Object.assign(this.utente, resp);
        this.updateUtente.emit(resp);
      });
    this.openDialog = false;
    Adsp.loading.hide();
  }
}
