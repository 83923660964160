<div class="row">
  <div class="col-12">
    <span
      class="
        label_underline
        d-block
        pb-2
        pt-5
        font-size-18
        color-dark-gray
        font-weight-bold
      "
    >
      {{ "MGU_USER_DETAIL_DATI_DIPENDENTE_TITLE" | translate }}
    </span>
  </div>
  <!--   <div class="row justify-content-center mt-4">
    <div
      *ngIf="errorMessage"
      class="w-100 alert alert-danger font-size-14"
      role="alert"
    >
      {{ errorMessage }}
    </div>
  </div> -->
  <div class="row w-100">
    <div class="col-9">
      <div class="row font-size-16">
        <div class="col-sm-6 mt-3 col-md-4 border-right-grey">
          <span class="d-block" translate>{{
            "MGU_USER_DETAIL_DATI_DIPENDENTE_EMAIL"
          }}</span>
          <span class="font-weight-bold word-break">{{
            soggettoFisicoEnte?.email || "---"
          }}</span>
        </div>
        <div class="col-sm-6 mt-3 col-md-4 border-right-grey">
          <span class="d-block" translate>{{
            "MGU_USER_DETAIL_DATI_DIPENDENTE_PEC"
          }}</span>
          <span class="font-weight-bold word-break">{{
            soggettoFisicoEnte?.pec || "---"
          }}</span>
        </div>
        <div class="col-sm-6 mt-3 col-md-4 border-right-grey">
          <span class="d-block" translate>{{
            "MGU_USER_DETAIL_DATI_DIPENDENTE_PHONE"
          }}</span>
          <span class="font-weight-bold word-break">{{
            soggettoFisicoEnte?.telefono || "---"
          }}</span>
        </div>
        <div class="col-sm-6 mt-3 col-md-4 border-right-grey">
          <span class="d-block" translate>{{
            "MGU_USER_DETAIL_DATI_DIPENDENTE_CATEGORIA"
          }}</span>
          <span class="font-weight-bold word-break">{{
            soggettoFisicoEnte?.categoria || "---"
          }}</span>
        </div>
        <div class="col-sm-6 mt-3 col-md-4 border-right-grey">
          <span class="d-block" translate>{{
            "MGU_USER_DETAIL_DATI_DIPENDENTE_DATA_INIZIO_VALIDITA"
          }}</span>
          <span class="font-weight-bold word-break">{{
            (soggettoFisicoEnte?.dataInizioValidita | date: "dd/MM/yyyy") ||
              "---"
          }}</span>
        </div>
        <div class="col-sm-6 mt-3 col-md-4 border-right-grey">
          <span class="d-block" translate>{{
            "MGU_USER_DETAIL_DATI_DIPENDENTE_DATA_FINE_VALIDITA"
          }}</span>
          <span class="font-weight-bold word-break">{{
            (soggettoFisicoEnte?.dataFineValidita | date: "dd/MM/yyyy") || "---"
          }}</span>
        </div>
        <div class="col-sm-6 mt-3 col-md-4 border-right-grey">
          <span class="d-block" translate>{{
            "MGU_USER_DETAIL_DATI_DIPENDENTE_VISIBILITA_RUBRICA"
          }}</span>
          <span class="font-weight-bold word-break">{{
            (soggettoFisicoEnte?.abilitatoRubrica
              ? "UTILS_ABILITATO"
              : "UTILS_DISABILITATO"
            ) | translate
          }}</span>
        </div>
        <div class="col-sm-6 mt-3 col-md-4 border-right-grey">
          <span class="d-block" translate>{{
            "MGU_USER_DETAIL_DATI_DIPENDENTE_ABILITATO_BACKOFFICE"
          }}</span>
          <span class="font-weight-bold word-break">{{
            (soggettoFisicoEnte?.abilitatoBackoffice
              ? "UTILS_ABILITATO"
              : "UTILS_DISABILITATO"
            ) | translate
          }}</span>
        </div>
        <div class="col-sm-6 mt-3 col-md-4 border-right-grey">
          <span class="d-block" translate>{{
            "MGU_USER_DETAIL_DATI_NOTIFICHE_ACCESSO"
          }}</span>
          <span class="font-weight-bold word-break">{{
            (soggettoFisicoEnte?.notificaControlloAccessi
              ? "UTILS_ABILITATO"
              : "UTILS_DISABILITATO"
            ) | translate
          }}</span>
        </div>
        <div class="col-sm-6 mt-3 col-md-4 border-right-grey">
          <span class="d-block" translate>{{
            "MGU_USER_DETAIL_DATI_DIPENDENTE_HR"
          }}</span>
          <span class="font-weight-bold word-break">{{
            (soggettoFisicoEnte?.hr ? "UTILS_YES" : "UTILS_NOT") | translate
          }}</span>
        </div>
        <div class="col-sm-6 mt-3 col-md-4 border-right-grey">
          <span class="d-block" translate>{{
            "MGU_USER_DETAIL_DATI_DIPENDENTE_FUNZIONE"
          }}</span>
          <span class="font-weight-bold word-break">{{
            (soggettoFisicoEnte?.funzione &&
              "TIPO_FUNZIONE_" + soggettoFisicoEnte?.funzione | translate) ||
              "---"
          }}</span>
        </div>
      </div>
    </div>
    <div class="col-3 align-self-center">
      <agid-button
        (click)="onEditEmployeeData()"
        class="float-right"
        size="small"
      >
        {{ "MGU_USER_DETAIL_BUTTONS_MANAGE_DATI_DIPENDENTE" | translate }}
      </agid-button>
    </div>
  </div>
</div>

<adsp-mgu-modale-soggetto-fisico
  (cancelled)="onDialogCancelled($event)"
  (completed)="onDialogCompleted($event)"
  [editCategoria]="editRecapiti"
  [editRecapiti]="editRecapiti"
  [elementSelected]="selectedSoggettoFisico"
  [info]="!editIncardinamento"
  [open]="openDialog"
  [uuidUtente]="utente.uuid"
  [errorMessage]="errorMessage"
>
</adsp-mgu-modale-soggetto-fisico>
