import { Incardinamento } from 'src/app/shared/models';

export interface Organizzazione {
  azienda: string;
  cap: string;
  categoria: string;
  cellulare: string;
  codNodoPagamenti: string;
  codiceFiscale: string;
  codiceUfficio: string;
  comuneUuid: string;
  comune?: string;
  dataCreazione: string;
  denominazione: string;
  email: string;
  indirizzo: string;
  orgFiglie: OrganizzazioneItem[];
  partitaIva: string;
  pec: string;
  provinciaUuid?: string;
  provincia?: string;
  strutturaPadri: OrganizzazionePadre[];
  telefono: string;
  uuid: string;
}

export interface OrganizzazionePadre {
  denominazione: string;
  livello: number;
  uuid: string;
}

export interface OrganizzazioneItem {
  categoria: string;
  codiceFiscale: string;
  codiceUfficio: string;
  denominazione: string;
  orgFiglie: OrganizzazioneItem[];
  uuid: string;
  incardinamento: Incardinamento;
}

export enum ORGANIZATION {
  NO_ORGANIZATION = 'NO_ORGANIZATION',
}
