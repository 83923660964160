import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Resolve } from '@angular/router';
declare const Adsp: any;
@Injectable()
export class CodiceVerticaleResolver implements Resolve<null> {
  constructor() {}

  resolve(): Observable<null> {
    localStorage.setItem('codiceVerticale', 'AMM');
    Adsp.events.auth.emitRoleChange(false);
    return null;
  }
}
