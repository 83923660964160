import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ApiClient} from '@eng-ds/api-client';
import {Observable, throwError} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {ApiFilterRequest, ApiFiltersRequest, ApiPageableResponse} from 'src/app/core/backend';
import {SelectOption} from 'src/app/shared/form';
import {Incardinamento} from 'src/app/shared/models';

import {ORGANIZATION, Organizzazione, OrganizzazioneItem, Utente} from '../models';
import {I18nService} from '@eng-ds/translate';

@Injectable()
export class OrganizzazioniService {
  constructor(
    private apiClient: ApiClient,
    private i18n: I18nService,
    private httpClient: HttpClient
  ) {}

  getOrganizzazioni(
    filters?: ApiFiltersRequest
  ): Observable<ApiPageableResponse<OrganizzazioneItem>> {
    return this.apiClient.request(
      'getOrganizzazioni',
      null,
      filters?.toServer()
    );
  }

  getUtenti(
    filters?: ApiFiltersRequest
  ): Observable<ApiPageableResponse<Utente>> {
    return this.apiClient.request('getUtenti', null, filters?.toServer()).pipe(
      map((value: any) => {
        value.utentiList.content.map((user: Utente) => {
          user.incardinamento = {
            dataFineValidita: '',
            dataInizioValidita: '',
            email: '',
            funzioneOrganizzazione: '',
            pec: '',
            telefono: '',
            uuid: user.uuid,
          };
          return user;
        });
        return value.utentiList;
      })
    );
  }

  getCategories(): Observable<SelectOption[]> {
    return this.apiClient.request('getCategories');
  }

  getFuncs(): Observable<SelectOption[]> {
    const tipoElenco = 'TIPO_FUNZIONE';
    return this.apiClient
      .request('listTipoElencoValori', null, {
        tipoElenco
      })
      .pipe(
        map((list: any[]) => {
          const selectOption: SelectOption[] = list.map((element) => ({
            value: element.codice,
            label: this.i18n.translate(`${tipoElenco}_${element.chiave}`),
          }));
          return selectOption;
        })
      );
  }

  getDettaglioOrganizzazione(uuid: string): Observable<Organizzazione> {
    return this.apiClient.request('getDettaglioOrganizzazione', null, null, {
      uuid,
    });
  }

  /**  If no uuid, will return all level 0 organizations */
  getOrganizationChildren(uuid?: string): Observable<SelectOption[]> {
    return this.apiClient
      .request('getOrganizzazioniFiglie', null, uuid && {uuid}, null)
      .pipe(
        map((data: any[]) => {
          data.push({
            label: 'Nessuna organizzazione',
            value: ORGANIZATION.NO_ORGANIZATION,
          });
          return data;
        })
      );
  }

  changeOrganizationHierarchy(
    uuid: string,
    uuidFather: string
  ): Observable<any> {
    return this.apiClient.request(
      'changeOrganizationHierarchy',
      null,
      uuidFather && {
        uuidPadre: uuidFather,
      },
      {uuid}
    );
  }

  getOrganizationChildrenList(
    uuid: string,
    filters?: ApiFiltersRequest
  ): Observable<ApiPageableResponse<OrganizzazioneItem>> {
    return this.apiClient.request(
      'getOrganigrammaOrganizzazione',
      null,
      filters?.toServer(),
      {uuid}
    );
  }

  deleteOrganization(uuid: string): Observable<any> {
    return this.apiClient.request('deleteOrganization', null, null, {uuid});
  }

  createOrganization(
    organization: Partial<Organizzazione>
  ): Observable<OrganizzazioneItem> {
    return this.apiClient.request('createOrganization', organization);
  }

  updateGeneralInfoOrganization(
    uuid: string,
    organization: Partial<Organizzazione>
  ): Observable<any> {
    return this.apiClient.request(
      'updateGeneralInfoOrganization',
      JSON.stringify(organization),
      null,
      {
        uuid,
      }
    );
  }

  getUtentiOrganizzazione(
    uuid: string,
    filters?: ApiFiltersRequest
  ): Observable<ApiPageableResponse<Utente>> {
    return this.apiClient.request(
      'getUtentiOrganizzazione',
      null,
      filters?.toServer(),
      {uuidOrg: uuid}
    );
  }

  associateUsersToOrganization(
    uuidOrg: string,
    incardinamento: Incardinamento[]
  ): Observable<any> {
    return this.apiClient.request(
      'associateUsersToOrganization',
      incardinamento,
      null,
      {uuid: uuidOrg}
    );
  }

  getParams(filters: any[]) {
    let params = new HttpParams();
    filters.forEach((element: { field: string; value: string }) => {
      params = params.set(element.field, element.value);
    });
    return params;
  }

  exportOrganizations(filters: ApiFilterRequest[]): Observable<any> {
    const url = this.apiClient.getUrlByApiName('exportOrganizzazione');
    const params = this.getParams(filters);

    return this.httpClient
      .request('GET', url, {
        params,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        tap((res: HttpResponse<Blob>) => {
          const blob = res.body;
          let fileName = null;
          const contentType = res.headers.get('content-type');

          // c'è un errore nella gestione della chiamata lato server
          // perchè questo caso non dovrebbe essere un blob
          if (contentType.indexOf('application/json') > -1) {
            throwError(new Error('The server response is application/json'));
          }

          // IE/EDGE seems not returning some response header
          // tslint:disable-next-line: no-duplicate-string
          if (res.headers.get('content-disposition')) {
            const contentDisposition = res.headers.get('content-disposition');
            fileName = contentDisposition
              .substring(contentDisposition.indexOf('=') + 1)
              .replace(/['"]+/g, '');
          } else {
            fileName =
              'unnamed.' + contentType.substring(contentType.indexOf('/') + 1);
          }

          if (window.navigator.msSaveOrOpenBlob) {
            // Internet Explorer
            window.navigator.msSaveOrOpenBlob(
              new Blob([blob], {type: contentType}),
              fileName
            );
          } else {
            const el: any = document.createElement('a');
            el.href = window.URL.createObjectURL(blob);
            el.download = fileName;
            el.click();
          }
        })
      );
  }
}
