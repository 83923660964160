import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiClient } from '@eng-ds/api-client';
import { Observable, throwError } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { SelectOption } from 'src/app/shared/form';
import { Incardinamento } from 'src/app/shared/models';

import {
  ApiFilterRequest,
  ApiFiltersRequest,
  ApiPageableResponse,
} from '../../../core/backend/models';
import { OrganizzazioneItem } from '../../organizzazioni/core/models';
import {
  AssociaOrganizzazioniUtente,
  SoggettoFisicoEnte,
  TipoStatoSua,
  Utente,
} from '../model';
import { AssociaRuoliUtente, RuoloUtente } from '../model/ruolo-utente.model';
import { formatDate } from '@angular/common';
import { Incarico } from '../../../shared/models/incarico.model';
import { I18nService } from '@eng-ds/translate';
import { Verticale } from '../../ruoli/model/verticale.model';

@Injectable({
  providedIn: 'root',
})
export class UtentiService {
  constructor(
    private apiClient: ApiClient,
    private httpClient: HttpClient,
    private i18n: I18nService
  ) {}

  getUtenti(
    filters?: ApiFiltersRequest
  ): Observable<ApiPageableResponse<Utente>> {
    return this.apiClient.request('getUtenti', null, filters?.toServer()).pipe(
      map((value: any) => {
        return value.utentiList;
      })
    );
  }

  /**
   *
   * Usare questa api per salvare le modifiche di soggettoFisicoEnte
   *
   * **/
  upsertContatto(contatto: SoggettoFisicoEnte): Observable<null> {
    return this.apiClient.request('upsertContatto', { ...contatto }, null, {
      uuid: contatto.uuid,
    });
  }

  setContatto(contatto: SoggettoFisicoEnte): Observable<null> {
    return this.apiClient.request('setContatto', { ...contatto }, null, {
      uuid: contatto.uuidSoggettoFisico,
    });
  }

  getCategories(): Observable<SelectOption[]> {
    return this.apiClient.request('getCategories');
  }

  getOrganizzazioni(
    filters?: ApiFiltersRequest
  ): Observable<ApiPageableResponse<OrganizzazioneItem>> {
    return this.apiClient
      .request('getOrganizzazioni', null, filters?.toServer())
      .pipe(
        map((value: any) => {
          value.content.map((org: OrganizzazioneItem) => {
            org.incardinamento = {
              dataFineValidita: '',
              dataInizioValidita: '',
              email: '',
              funzioneOrganizzazione: '',
              pec: '',
              telefono: '',
              uuid: org.uuid,
            };
            return org;
          });
          return value;
        })
      );
  }

  getParams(filters: any[]) {
    let params = new HttpParams();
    filters.forEach((element: { field: string; value: string }) => {
      params = params.set(element.field, element.value);
    });
    return params;
  }

  export(filters: ApiFilterRequest[]): Observable<any> {
    const url = this.apiClient.getUrlByApiName('exportUtenti');
    const params = this.getParams(filters);

    return this.httpClient
      .request('GET', url, {
        params,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        tap((res: HttpResponse<Blob>) => {
          const blob = res.body;
          let fileName = null;
          const contentType = res.headers.get('content-type');

          // c'è un errore nella gestione della chiamata lato server
          // perchè questo caso non dovrebbe essere un blob
          if (contentType.indexOf('application/json') > -1) {
            throwError(new Error('The server response is application/json'));
          }

          // IE/EDGE seems not returning some response header
          // tslint:disable-next-line: no-duplicate-string
          if (res.headers.get('content-disposition')) {
            const contentDisposition = res.headers.get('content-disposition');
            fileName = contentDisposition
              .substring(contentDisposition.indexOf('=') + 1)
              .replace(/['"]+/g, '');
          } else {
            fileName =
              'unnamed.' + contentType.substring(contentType.indexOf('/') + 1);
          }

          if (window.navigator.msSaveOrOpenBlob) {
            // Internet Explorer
            window.navigator.msSaveOrOpenBlob(
              new Blob([blob], { type: contentType }),
              fileName
            );
          } else {
            const el: any = document.createElement('a');
            el.href = window.URL.createObjectURL(blob);
            el.download = fileName;
            el.click();
          }
        })
      );
  }

  getRuoliUtente(uuid: string): Observable<ApiPageableResponse<RuoloUtente>> {
    return this.apiClient
      .request('getRuoliUtente', null, null, {
        uuid,
      })
      .pipe(
        map(({ ruoliUtente }: any) => {
          return {
            content: ruoliUtente,
          } as ApiPageableResponse<RuoloUtente>;
        })
      );
  }

  getRuoli(): Observable<RuoloUtente[]> {
    return this.apiClient.request('getRuoli', null, { tipo: 'PROCEDIMENTO' });
  }

  associaRuoliUtente(ruoliUtente: AssociaRuoliUtente): Observable<any> {
    return this.apiClient.request(
      'associaRuoliUtente',
      JSON.stringify(ruoliUtente)
    );
  }

  getUtente(uuid: string): Observable<Utente> {
    return this.apiClient
      .request('getUtente', null, null, {
        uuid,
      })
      .pipe(
        map((value: any) => {
          if (value.soggettoFisicoEnte) {
            value.soggettoFisicoEnte.dataFineValidita = value
              ?.soggettoFisicoEnte?.dataFineValidita
              ? formatDate(
                  value.soggettoFisicoEnte.dataFineValidita,
                  'yyyy-MM-ddThh:mm',
                  'it-IT'
                )
              : null;
            value.soggettoFisicoEnte.dataInizioValidita = value
              ?.soggettoFisicoEnte?.dataInizioValidita
              ? formatDate(
                  value.soggettoFisicoEnte.dataInizioValidita,
                  'yyyy-MM-ddThh:mm',
                  'it-IT'
                )
              : null;
          }
          return value;
        })
      );
  }

  associaOrganizzazioniUtente(
    association: AssociaOrganizzazioniUtente
  ): Observable<any> {
    return this.apiClient.request('associaOrganizzazioniUtente', association);
  }

  selectTipoProfilo(): Observable<any> {
    return this.apiClient.request('tipoProfilo');
  }

  updateOrganizzazioneUtente(
    organizzazione: Incardinamento,
    uuidUtente: string
  ): Observable<any> {
    return this.apiClient.request(
      'updateOrganizzazioniUtente',
      organizzazione,
      null,
      { uuid: uuidUtente }
    );
  }

  associaProcedimentoUtente(
    procedimenti: any[],
    uuidUtente: string
  ): Observable<any> {
    const addProcedimentiRuoliRequest: any = {
      procedimentiRuoliList: procedimenti,
      uuidUtente,
    };
    return this.apiClient.request(
      'associaProcedimentiUtente',
      addProcedimentiRuoliRequest
    );
  }

  creaAccountSua(uuidSoggettoFisico: string, username: string) {
    return this.apiClient.request(
      'creaAccountSua',
      null,
      { username },
      { uuidSoggettoFisico }
    );
  }

  getProcedimentiAssociati(
    uuidUtente?: string,
    filters?: ApiFiltersRequest
  ): Observable<ApiPageableResponse<any>> {
    return this.apiClient.request(
      'getProcedimentiAssociatiUtente',
      null,
      filters?.toServer(),
      { uuidUtente }
    );
  }

  getUserRolesList(
    uuidSoggettoFisico: string,
    verticaleVisibile: 'TUTTI' | 'SI' | 'NO' = 'TUTTI'
  ): Observable<Verticale[]> {
    return this.apiClient.request<Verticale[]>(
      'getRuoliListBySoggettoFisico',
      null,
      { verticaleVisibile },
      {
        uuidSoggettoFisico,
      }
    );
  }

  getIncarichi(
    uuidSoggettoFisico: string,
    filters?: ApiFiltersRequest
  ): Observable<Incarico[]> {
    return this.apiClient.request('getIncarichi', null, filters?.toServer(), {
      uuidSoggettoFisico,
    });
  }

  addIncarico(
    uuidSoggettoFisico: string,
    request: {
      dataFine: string;
      dataInizio: string;
      portoRiferimentoCodice: string;
      tipoIncaricoCodice: string;
    },
    filters?: ApiFiltersRequest
  ): Observable<Incarico> {
    return this.apiClient.request('addIncarico', request, filters?.toServer(), {
      uuidSoggettoFisico,
    });
  }

  editIncarico(
    uuid: string,
    request: {
      dataFine: string;
      dataInizio: string;
      portoRiferimentoCodice: string;
      tipoIncaricoCodice: string;
    },
    filters?: ApiFiltersRequest
  ): Observable<Incarico> {
    return this.apiClient.request(
      'editIncarico',
      request,
      filters?.toServer(),
      { uuid }
    );
  }

  updateProcedimentoUtente(procedimento): Observable<any> {
    return this.apiClient.request('updateProcedimentoUtente', procedimento);
  }

  removeProcedimentoAssociato(uuidRelazione): Observable<any> {
    return this.apiClient.request(
      'rimuoviAssociazioneProcedimentoUtente',
      null,
      null,
      {
        uuidRelazione,
      }
    );
  }

  bloccaUtente(utente: Utente): Observable<any> {
    let changeStateRequest: string;
    utente.stato === 'BLOCKED'
      ? (changeStateRequest = 'UNBLOCKED')
      : (changeStateRequest = 'BLOCKED');
    return this.apiClient.request(
      'bloccaUtente',
      { action: changeStateRequest },
      null,
      {
        uuid: utente.uuid,
      }
    );
  }

  resetPassword(utente: Utente): Observable<any> {
    return this.apiClient.request('resetPassword', null, null, {
      uuid: utente.uuid,
    });
  }

  getTipologieIncarico() {
    const tipoElenco = 'TIPO_INCARICO';
    return this.apiClient
      .request('listTipoElencoValori', null, {
        tipoElenco,
      })
      .pipe(
        map((list: any[]) => {
          const selectOption: SelectOption[] = list.map((element) => ({
            value: element.codice,
            label: this.i18n.translate(`${tipoElenco}_${element.chiave}`),
          }));
          return selectOption;
        })
      );
  }

  getPortiRiferimento() {
    const tipoElenco = 'PORTO_RIFERIMENTO';
    return this.apiClient
      .request('listTipoElencoValori', null, {
        tipoElenco,
      })
      .pipe(
        map((list: any[]) => {
          const selectOption: SelectOption[] = list.map((element) => ({
            value: element.codice,
            label:
              element.chiave[0].toUpperCase() +
              element.chiave.substr(1).toLowerCase(),
          }));
          return selectOption;
        })
      );
  }

  validaUtente(utente: Utente, statoSua: string): Observable<any> {
    return this.apiClient.request('validaUtente', { statoSua }, null, {
      uuid: utente.uuid,
    });
  }

  getStatiSua(): Observable<SelectOption[]> {
    return this.apiClient.request('statiSua');
  }

  inserisciUtente(utente): Observable<Utente> {
    return this.apiClient.request('inserisciUtente', { ...utente });
  }

  modificaUtente(): Observable<Utente> {
    return this.apiClient.request('modificaUtente');
  }
}
