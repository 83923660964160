import {Component, EventEmitter, Input, OnInit, Output, TemplateRef,} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {AutoUnsubscribe} from 'src/app/core/components';
import {TableColumn} from '../../models/column.model';
import {DataSource} from '../../models/data-source';
import {ServerPageableDataSource} from '../../models/server-pageable-data-source';
import {BaseRow} from '../../models/base-row.model';

declare const Adsp: any;

interface PagerEvent {
  page?: number;
  size?: number;
}

@Component({
  selector: 'adsp-table',
  templateUrl: './table.component.html',
})
export class TableComponent<T extends BaseRow> extends AutoUnsubscribe
  implements OnInit {
  @Output() rowClick: EventEmitter<T> = new EventEmitter();
  // tslint:disable-next-line: no-output-native
  @Output() select: EventEmitter<T[]> = new EventEmitter();
  /**
   * Rige pre selezionate
   */
  @Input() selected: T[] = [];
  /**
   * Eventuale dettaglio della riga
   */
  @Input() rowDetail: TemplateRef<any>;
  /**
   * Eventuale riga da far vedere nel caso di nessuna row
   */
  @Input() emptyMessage = 'UTILS_TABLE_EMPTY';
  /**
   * Indica se mostrare il bordo della riga di dettaglio
   */
  @Input() rowDetailBorder = true;
  /**
   * Eventuale dettaglio della riga
   */
  @Input() tableClass = 'table';
  /**
   * Indica se visualizzare la header
   */
  @Input() showHeader = true;
  /**
   * Indica se visualizzare il paginatore
   */
  @Input() showPager = true;

  get isRowClickWired(): boolean {
    return this.rowClick.observers.length > 0;
  }

  // tslint:disable-next-line: variable-name
  _dataSource: DataSource<T> | ServerPageableDataSource<T>;

  get dataSource() {
    return this._dataSource;
  }

  /**
   * Datasource della tabella
   */
  @Input() set dataSource(value: DataSource<T> | ServerPageableDataSource<T>) {
    // appena il datasource è valorizzato
    this._dataSource = value;
    // loading hide ogni che le righe cambiano
    this._initHideLoadingSubscribe();
  }

  // tslint:disable-next-line: variable-name
  _columns: TableColumn[];

  get columns(): TableColumn[] {
    return this._columns;
  }

  /**
   * Colonne della tabella
   */
  @Input() set columns(value: TableColumn[]) {
    // apena le colonne sono valorizzate
    // si imposta il sorting di default a true
    this._columns = value.map((c) => ({
      ...c,
      sortable: c.sortable === undefined ? true : c.sortable,
    }));
  }

  /**
   * Questa funzione verrà usata per comparare le righe selezionate
   *
   * (`fn(x) === fn(y)` instead of `x === y`)
   */
  @Input() rowIdentity: (x: T) => string | number = (x: T) => {
    return x.uuid;
  };

  ngOnInit() {
    // popolamento iniziale

    setTimeout(() => {
      this.dataSource?.refresh();
    });
  }

  // evento dal pager
  pagerChange(data: CustomEvent<PagerEvent>) {
    // cambio pagina
    if (data.detail.page) {
      Adsp.loading.show();
      this.dataSource.paging(data.detail.page);
    }
    // cambio size pagina
    if (data.detail.size) {
      Adsp.loading.show();
      this.dataSource.paging(1, data.detail.size);
    }
  }

  checkboxToggleHeader(event: Event) {
    if ((event.target as HTMLInputElement).checked) {
      // aggiunge ai selezionati solo quelli della pagina corrente
      // facendo attenzione ai selezionati già presenti per evitare duplicati
      this.dataSource.rows.forEach((r) => {
        // inserisce tra i selezionati solo se non già presente
        if (
          this.selected.findIndex(
            (sr) => this.rowIdentity(sr) === this.rowIdentity(r)
          ) === -1
        ) {
          this.selected.push(r);
        }
      });
    } else {
      // elimina dai selezionati solo quelli della pagina corrente
      this.selected = [
        ...this.selected.filter(
          (sr) =>
            this.dataSource.rows.findIndex(
              (r) => this.rowIdentity(sr) === this.rowIdentity(r)
            ) === -1
        ),
      ];
    }

    this.select.emit(this.selected);
  }

  checkboxToggleRow({event, row}: { event: Event; row: T }) {
    if ((event.target as HTMLInputElement).checked) {
      this.selected.push(row);
    } else {
      this.selected = this.selected.filter(
        (r) => this.rowIdentity(r) !== this.rowIdentity(row)
      );
    }

    this.select.emit(this.selected);
  }

  private _initHideLoadingSubscribe(): void {
    this.dataSource.rows$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      Adsp.loading.hide();
    });
  }
}
