import { BaseComponent } from '../../../core/components';
import { Component, Input } from '@angular/core';
import { I18nService } from '@eng-ds/translate';

declare const AgidWebComponents: any;
declare const Adsp: any;

@Component({
  selector: 'adsp-mgu-modifica-username-bo',
  templateUrl: './associa-username-bo.component.html',
  styleUrls: ['./associa-username-bo.component.css'],
})
export class AssociaUsernameBoComponent extends BaseComponent {
  @Input() username: string;
  @Input() soggettoFisicoUuid: string;
  @Input() email: string;
  openDialog = false;

  constructor(protected i18n: I18nService) {
    super(i18n);
  }

  open() {
    if (this.email) {
      this.openDialog = true;
    } else {
      AgidWebComponents.notifications.warning(
        this.i18n.translate('MGU_USER_DETAIL_USERNAME_ERROR_NO_MAIL_TITLE'),
        this.i18n.translate('MGU_USER_DETAIL_USERNAME_ERROR_NO_MAIL_TEXT'),
        { position: 'right' }
      );
    }
  }

  onClose(username: string) {
    this.openDialog = false;
    if (username) {
      this.username = username;
    }
  }
}
