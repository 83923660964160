import { Component, Input } from '@angular/core';
import { RadiosInput } from '../../../models/inputs/radios-input';
import { BaseInputComponent } from '../base-input/base-input.component';

@Component({
  selector: 'app-radios-input',
  template: ``,
})
export class RadiosInputComponent extends BaseInputComponent {
  @Input() name: string;
  @Input() control: RadiosInput;
}

/**
 * <nb-radio-group
      [status]="control.colorStatus"
      [formControl]="control"
      [class.ml-3]="control.inline"
    >
      <nb-radio
        *ngFor="let option of control.options | async"
        [value]="option.value"
        [class.inline]="control.inline"
      >
        {{ option.label | translate }}
      </nb-radio>
    </nb-radio-group>

    <button
      *ngIf="control.clearable"
      type="button"
      nbButton
      status="basic"
      size="small"
      (click)="control.reset()"
    >
      <nb-icon icon="close-outline"></nb-icon> {{ 'UTILS.CLEAR' | translate }}
    </button>
 */
