import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Form, TextInput, ValidationStatus } from '../../../form';
import { I18nService } from '@eng-ds/translate';
import { UtentiService } from '../../../../pages/utenti/services';
import { catchError } from 'rxjs/operators';
import { BaseComponent } from '../../../../core/components';
import { throwError } from 'rxjs';

declare const AgidWebComponents: any;
declare const Adsp: any;

@Component({
  selector: 'adsp-mgu-dialog-modifica-username',
  templateUrl: './dialog-associazione-username.component.html',
})
export class DialogAssociazioneUsernameComponent
  extends BaseComponent
  implements OnInit
{
  @Input() soggettoFisicoUuid: string;
  @Output() closedDialog: EventEmitter<string> = new EventEmitter();
  form: Form;
  errorMessage: string;

  constructor(protected i18n: I18nService, private service: UtentiService) {
    super(i18n);
  }

  private _open = false;

  get open(): boolean {
    return this._open;
  }

  @Input() set open(value: boolean) {
    this._open = value;
    if (this._open) {
      this._getForm();
    }
  }

  ngOnInit(): void {
    this._getForm();
  }

  save() {
    this.service
      .creaAccountSua(this.soggettoFisicoUuid, this.form.get('username').value)
      .pipe(
        catchError((err) => {
          this.errorMessage = this._controlTranslate(err.error);
          return throwError(err);
        })
      )
      .subscribe(() => {
        this.onClose(this.form.get('username').value);
        AgidWebComponents.notifications.success(
          this.i18n.translate('MGU_USER_DETAIL_USERNAME_DIALOG_DONE_TITLE'),
          this.i18n.translate('MGU_USER_DETAIL_USERNAME_DIALOG_DONE_TEXT'),
          { position: 'right' }
        );
      });
  }

  onClose(username?: string) {
    this.errorMessage = null;
    this.open = false;
    this.closedDialog.emit(username);
  }

  private _getForm() {
    this.form = new Form({
      header: { show: true },
      controls: {
        username: new TextInput({
          type: 'text',
          required: true,
          label: 'MGU_USER_DETAIL_USERNAME',
          placeholder: 'MGU_USER_DETAIL_USERNAME_PLACEHOLDER',
          size: '12|12|12|12|12',
          validationStatus: [ValidationStatus.ERROR.REQUIRED],
        }),
      },
    });
  }
}
