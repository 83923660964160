import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AutocompleteInput,
  BaseInput,
  DateInput,
  Form,
  ValidationStatus,
} from '../../../form';
import { I18nService } from '@eng-ds/translate';
import { Incarico } from '../../../models/incarico.model';
import { UtentiService } from '../../../../pages/utenti/services';
import { catchError, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../../../core/components';
import { formatDate } from '@angular/common';
import { throwError } from 'rxjs';

@Component({
  selector: 'adsp-mgu-modal-incarico',
  templateUrl: './modal-incarico.component.html',
  styleUrls: ['./modal-incarico.component.css'],
})
export class ModalIncaricoComponent extends BaseComponent implements OnInit {
  formGroup: Form;
  @Input() uuidSoggettoFisico: string;
  @Input() incarico: Incarico;
  @Output() closed = new EventEmitter<Incarico>();
  errorMessage: string;
  constructor(protected i18n: I18nService, private service: UtentiService) {
    super(i18n);
  }

  private _open = false;

  get open(): boolean {
    return this._open;
  }

  @Input() set open(value: boolean) {
    this.errorMessage = '';
    this._open = value;
    if (this._open) {
      this._getForm();
    }
  }

  ngOnInit(): void {
    this._getForm();
  }

  close(incarico?: Incarico) {
    this.open = false;
    this.closed.emit(incarico);
  }

  save() {
    const incarico = this.formGroup.getRawValue();
    incarico.dataInizio = incarico.dataInizio
      ? formatDate(incarico.dataInizio, 'yyyy-MM-ddT00:00', 'IT-it')
      : null;
    incarico.dataFine = incarico.dataFine
      ? formatDate(incarico.dataFine, 'yyyy-MM-ddT23:59', 'IT-it')
      : null;
    if (this.incarico?.uuid) {
      this.service
        .editIncarico(this.incarico.uuid, incarico)
        .pipe(
          catchError((err) => {
            this.errorMessage = this._controlTranslate(err.error);
            return throwError(err);
          })
        )
        .subscribe(() => this.close(incarico));
    } else {
      this.service
        .addIncarico(this.uuidSoggettoFisico, incarico)
        .pipe(
          catchError((err) => {
            this.errorMessage = this._controlTranslate(err.error);
            return throwError(err);
          })
        )
        .subscribe(() => this.close(incarico));
    }
  }

  private _getForm() {
    this.formGroup = new Form({
      header: { show: false },
      controls: this._getFields(),
    });
  }

  private _getFields(): { [key: string]: BaseInput<any> } {
    const size = '12|12|6|6|6';
    return {
      tipoIncaricoCodice: new AutocompleteInput({
        size,
        required: true,
        value: this.incarico?.tipoIncaricoCodice,
        label: 'MGU_UTENTE_INCARICHI_EDIT_MODAL_FORM_INPUT_TIPO_INCARICO_LABEL',
        placeholder:
          'MGU_UTENTE_INCARICHI_EDIT_MODAL_FORM_INPUT_TIPO_INCARICO_PLACEHOLDER',
        validationStatus: [ValidationStatus.ERROR.REQUIRED],
        options: this.service.getTipologieIncarico(),
      }),
      portoRiferimentoCodice: new AutocompleteInput({
        size,
        required: true,
        value: this.incarico?.portoRiferimentoCodice,
        label:
          'MGU_UTENTE_INCARICHI_EDIT_MODAL_FORM_INPUT_PORTO_RIFERIMENTO_LABEL',
        placeholder:
          'MGU_UTENTE_INCARICHI_EDIT_MODAL_FORM_INPUT_PORTO_RIFERIMENTO_PLACEHOLDER',
        validationStatus: [ValidationStatus.ERROR.REQUIRED],
        options: this.service.getPortiRiferimento(),
      }),
      dataInizio: new DateInput({
        formGroupClass: ['mb-3'],
        required: true,
        value: this.incarico?.dataInizio
          ? formatDate(this.incarico?.dataInizio, 'yyyy-MM-dd', 'IT-it')
          : null,
        validationStatus: [
          ValidationStatus.ERROR.REQUIRED,
          ValidationStatus.WARNING((control) => control.hasError('maxlength'), {
            text: this.i18n.translate('VALIDATIONS_PATTERN_DATE'),
          }),
        ],
        label: 'MGU_UTENTE_INCARICHI_EDIT_MODAL_FORM_BEGIN_VALIDITY_LABEL',
        placeholder:
          'MGU_UTENTE_INCARICHI_EDIT_MODAL_FORM_BEGIN_VALIDITY_PLACEHOLDER',
        size,
      }),
      dataFine: new DateInput({
        formGroupClass: ['mb-3'],
        value: this.incarico?.dataFine
          ? formatDate(this.incarico?.dataFine, 'yyyy-MM-dd', 'IT-it')
          : null,
        validationStatus: [
          ValidationStatus.ERROR.REQUIRED,
          ValidationStatus.WARNING((control) => control.hasError('maxlength'), {
            text: this.i18n.translate('VALIDATIONS_PATTERN_DATE'),
          }),
        ],
        label: 'MGU_UTENTE_INCARICHI_EDIT_MODAL_FORM_END_VALIDITY_LABEL',
        placeholder:
          'MGU_UTENTE_INCARICHI_EDIT_MODAL_FORM_END_VALIDITY_PLACEHOLDER',
        size,
      }),
    };
  }
}
