import { Ruolo } from '../../ruoli/model';
import { Verticale } from '../../ruoli/model/verticale.model';

export interface SoggettoFisicoEnte {
  abilitatoBackoffice: boolean;
  abilitatoRubrica: boolean;
  categoria: string;
  dataFineValidita: string;
  dataInizioValidita: string;
  email: string;
  pec: string;
  telefono: string;
  uuidSoggettoFisico?: string;
  uuid?: string;
  funzione?: string;
  funzioneCodice?: string;
  categoriaCodice?: string;
  hr: boolean;
  notificaControlloAccessi: boolean;
}

export interface Utente {
  cap: string;
  pec: string;
  codiceFiscale: string;
  cognome: string;
  comune: string;
  email: string;
  indirizzo: string;
  username?: string;
  nome: string;
  organizzazioni: any[];
  ruoli: Ruolo[];
  ruoliPerVerticali: Verticale[];
  telefonoFisso: string;
  uuid: string;
  stato: string;
  soggettoFisicoEnte: SoggettoFisicoEnte;
  statoSua: TipoStatoSua;
  spid: boolean;

  consensoTrattamentoDati: boolean;
  cittaEsteraResidenza?: string;
  cittaEsteraNascita?: string;

  codiceRuolo?: string;
  lingua: string;

  statoAutenticazione: string;
  codiceLingua?: string;
  dataNascita?: any;

  comuneNascitaUuid?: string;
  provinciaNascitaUuid?: string;
  nazioneNascitaUuid?: string;
  comuneResidenzaUuid?: string;
  provinciaResidenzaUuid?: string;
  nazioneResidenzaUuid?: string;

  sesso: 'M' | 'F';

  comuneNascita: string;
  comuneResidenza: string;

  nazioneNascita: string;
  nazioneResidenza: string;
  provinciaNascita: string;
  provinciaResidenza: string;

  statoUtente: string;
  telefonoCellulare: string;
}
export enum TipoStatoSua {
  DA_VERIFICARE = 'DA_VERIFICARE',
  APPROVATO = 'APPROVATO',
  NEGATO = 'NEGATO',
}
