import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { I18nService } from '@eng-ds/translate';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AutoUnsubscribe } from 'src/app/core/components';
import { RuoloUtente } from 'src/app/pages/utenti/model/ruolo-utente.model';
import { UtentiService } from 'src/app/pages/utenti/services';
import {
  AutocompleteInput,
  BaseInput,
  CheckboxInput,
  DateInput,
  Form,
  SelectOption,
  ValidationStatus,
} from 'src/app/shared/form';

@Component({
  selector: 'adsp-mgu-modale-ruolo-utente',
  templateUrl: './modale-ruolo-utente.component.html',
  styleUrls: ['./modale-ruolo-utente.component.css'],
})
export class ModaleRuoloUtenteComponent
  extends AutoUnsubscribe
  implements OnInit
{
  @Output() incardinamentoCompleted: EventEmitter<any[]> = new EventEmitter();
  @Output() incardinamentoCancelled: EventEmitter<boolean> = new EventEmitter();
  formGroupIncardinamento: FormGroup;
  optionsRolesSelect: Observable<SelectOption<string, string>[]> =
    this.getOptions(this.service.getRuoli());
  ruoli: RuoloUtente[];

  constructor(private service: UtentiService, private i18n: I18nService) {
    super();
  }

  private _elementSelected: any[];

  get elementSelected(): any[] {
    return this._elementSelected;
  }

  @Input() set elementSelected(value: any[]) {
    this._elementSelected = value;
    this.initForm(this._elementSelected);
  }

  private _open = false;

  get open(): boolean {
    return this._open;
  }

  @Input() set open(value: boolean) {
    this._open = value;
    if (this._open) {
      this.initForm(this._elementSelected);
    }
  }

  ngOnInit(): void {}

  annullaIncardinamentoOrganizzazione(): void {
    this._open = false;
    this.incardinamentoCancelled.emit(true);
  }

  salvaIncardinamentoOrganizzazione(): void {
    const value = this.formGroupIncardinamento.value;

    this.elementSelected.map((procedimento: any) => {
      procedimento.dataInizioValidita =
        value[procedimento.uuid].dataInizioValidita;
      procedimento.dataFineValidita = value[procedimento.uuid].dataFineValidita;
      procedimento.uuidRuolo = value[procedimento.uuid].ruolo;
      procedimento.uuidProcedimento = procedimento.uuid;
      procedimento.utenteDefault = value[procedimento.uuid].utenteDefault;
      return procedimento;
    });

    this.open = false;

    // this gains time form the dialog to hide properly
    setTimeout(() => {
      this.incardinamentoCompleted.emit(this.elementSelected);
    }, 100);
  }

  private initForm(selected: any[]): void {
    this.formGroupIncardinamento = new FormGroup({});
    selected.forEach((iEsteso: any) => {
      this.formGroupIncardinamento.addControl(
        iEsteso.uuid,
        this._getForm(iEsteso)
      );
    });
    this.formGroupIncardinamento.markAllAsTouched();
  }

  private _getForm(iEsteso: any) {
    return new Form({
      header: {
        show: false,
      },
      controls: this._getIncardinamentoFields(iEsteso),
    });
  }

  private _getIncardinamentoFields(iEsteso: any): {
    [key: string]: BaseInput<any>;
  } {
    return {
      ruolo: new AutocompleteInput({
        formGroupClass: ['mb-2'],
        size: '12|12|12|12|12',
        placeholder: 'MGU_PROCEDIMENTO_MODAL_FORM_FILTER_RUOLO_PLACEHOLER',
        options: this.optionsRolesSelect,
        value: iEsteso.ruolo ? iEsteso.ruolo : null,
        label: 'MGU_PROCEDIMENTO_MODAL_FORM_FILTER_RUOLO',
        required: true,
        validationStatus: [ValidationStatus.ERROR.REQUIRED],
        valueChange: (value: string) => {
          const subForm = this.formGroupIncardinamento.get(
            iEsteso.uuid
          ) as Form;
          const role = this.ruoli.find(
            (el: RuoloUtente) => el.uuidRuolo === value
          );

          if (role?.definisciUtenteDefault) {
            subForm.addControl(
              'utenteDefault',
              new CheckboxInput({
                label: this.i18n.translate(
                  'MGU_PROCEDIMENTO_MODAL_FORM_FILTER_DEFAULT_USER'
                ),
                value: iEsteso.utenteDefault,
              }) as BaseInput<any>
            );
          } else if (subForm?.get('utenteDefault')) {
            subForm.removeControl('utenteDefault');
          }
        },
      }),
      dataInizioValidita: new DateInput({
        value: iEsteso?.dataInizioValidita
          ? formatDate(iEsteso?.dataInizioValidita, 'yyyy-MM-dd', 'IT-it')
          : formatDate(new Date(), 'yyyy-MM-dd', 'IT-it'),
        label: 'MGU_INCARDINAMENTO_MODAL_FORM_START_DATE',
        size: '12|12|12|6|6',
        required: true,
        formGroupClass: ['mb-2'],
        validationStatus: [
          ValidationStatus.ERROR.REQUIRED,
          ValidationStatus.WARNING((control) => control.hasError('maxlength'), {
            text: this.i18n.translate('VALIDATIONS_PATTERN_DATE'),
          }),
        ],
      }),
      dataFineValidita: new DateInput({
        value: iEsteso?.dataFineValidita
          ? formatDate(iEsteso?.dataFineValidita, 'yyyy-MM-dd', 'IT-it')
          : null,
        label: 'MGU_INCARDINAMENTO_MODAL_FORM_END_DATE',
        formGroupClass: ['mb-2'],
        size: '12|12|12|6|6',
        validationStatus: [
          ValidationStatus.WARNING((control) => control.hasError('maxlength'), {
            text: this.i18n.translate('VALIDATIONS_PATTERN_DATE'),
          }),
        ],
      }),
    };
  }

  getOptions(obs): Observable<SelectOption<string, string>[]> {
    return obs.pipe(
      map((data: any) => {
        this.ruoli = data.content;
        const labelValues: SelectOption[] = [];
        this.ruoli.forEach((element) => {
          labelValues.push({
            label: element.descrizioneRuolo,
            value: element.uuidRuolo,
          });
        });
        return labelValues;
      })
    );
  }
}
