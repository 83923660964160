import { Component, HostListener, Input } from '@angular/core';
import { BaseInputComponent } from '../base-input/base-input.component';
import { FileInput } from '../../../models/inputs/file-input';

@Component({
  selector: 'app-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss'],
})
export class FileInputComponent extends BaseInputComponent {
  @Input() name: string;
  @Input() control: FileInput;
  onChange: () => any;
  file: File | null = null;

  @HostListener('change', ['$event.target.files']) emitFiles(event: FileList) {
    const file = event && event.item(0);
    this.file = file;
    this.control.setValue(this.file);
  }
}
