export const IT = {
  TITLE_PAGE_NEW_ORGANIZZ: 'Nuova organizzazione',
  TITLE_PAGE_EDIT_ORGANIZZ: 'Modifica organizzazione',
  TITLE_PAGE_DETAIL_ORGANIZZ: 'Dettaglio organizzazione',
  TITLE_PAGE_LIST_ORGANIZZ: 'Lista organizzazioni',
  TITLE_PAGE_ASSOC_UTENTI: 'Associa utenti',
  TITLE_PAGE_LIST_UTENTI: 'Lista utenti',
  TITLE_PAGE_DETAIL_UTENTE: 'Dettaglio utente',
  TITLE_PAGE_ASSOCIA_RUOLO: 'Associa ruolo',
  TITLE_PAGE_ASSOCIA_ORGANIZZ: 'Associa organizzazioni',
  TITLE_PAGE_ASSOCIA_PROC: 'Associa procedimenti',
  TITLE_PAGE_LIST_ROLE: 'Lista ruoli',
  TITLE_PAGE_LIST_VERTICALI: 'Lista verticali',
  TITLE_PAGE_DETAIL_ROLE: 'Dettaglio ruolo',
  TITLE_PAGE_MGU_LIST_DELEGHE: 'Lista deleghe',
  TITLE_PAGE_MGU_DETAIL_DELEGA: 'Dettaglio deleghe',
  TITLE_PAGE_MGU_EDIT_VALIDITY: 'Modifica validità',
  TITLE_PAGE_MGU_GEST_PROFILO: 'Gestione profilo',
  TITLE_PAGE_INSERISCI_UTENTE: 'Inserisci utente',
  VALIDATIONS_PATTERN_CF: 'CodiceFiscale non valido',
  AUTOCOMPLETE_TRANSLATE_LABEL: 'Select label',
  AUTOCOMPLETE_TRANSLATE_PLACEHOLDER: 'Select placeholder',
  AUTOCOMPLETE_TRANSLATE_PLACEHOLDER_ALL: 'Tutti',
  VALIDATIONS_PATTERN_EMAIL: 'Email non corretta',
  TEXT_TRANSLATE_LABEL: 'Text label',
  TEXT_TRANSLATE_PLACEHOLDER: 'Text placeholder',
  EXPORT_LIST_BUTTON: 'Esporta elenco',

  DATE_CREATION: 'Creato il',
  // TOASTR
  UTILS_TOASTR_ERROR_GENERIC_TITLE: 'Errore',
  UTILS_TOASTR_ERROR_GENERIC_MESSAGE: 'C\'è stato un errore, riprova più tardi',

  FORM_SAVE: 'Salva',
  OK_CLOSE: 'OK, Chiudi',
  CLOSE_PUBBLICATION: 'Chiudi e Pubblica',
  FORM_FILTER: 'Filtra',
  FORM_RESET: 'Cancella',

  COMPONENT_FORM_TITLE: 'Componente form',
  SINGLE_INPUTS_TITLE: 'Form singoli input',

  UTILS_TABLE_EMPTY: 'Nessun elemento trovato',
  UTILS_FOUND_RESULTS: 'risultati trovati',
  UTILS_CONFIRM: 'Conferma',
  UTILS_FORM_LABEL_NOT_MANDATORY: 'facoltativo',
  UTILS_REMOVE: 'Rimuovi',
  UTILS_CANCEL: 'Annulla',
  UTILS_EXPORT: 'Esporta elenco',
  UTILS_AUTOCOMPLETE_INSERT_MIN_CHARACTERS: 'Inserire almeno 3 caratteri',
  UTILS_AUTOCOMPLETE_LOADING: 'Caricamento...',
  UTILS_AUTOCOMPLETE_ITEMS_NOT_FOUND: 'Nessun elemento trovato',
  UTILS_AUTOCOMPLETE_TYPE_TO_SEARCH: 'Digita per ricercare',
  UTILS_CLOSE: 'Chiudi',
  UTILS_ERROR: 'Errore',
  UTILS_WARNING: 'Attenzione',
  UTILS_ABILITATO: 'Abilitato',
  UTILS_DISABILITATO: 'Disabilitato',
  UTILS_NOT: 'No',
  UTILS_YES: 'Si',

  BLOCKED_ACTION_USER: 'Sblocca utente',
  UNBLOCKED_ACTION_USER: 'Blocca utente',
  null_ACTION_USER: 'Blocca utente',

  ACTIONS_FOOTER_SAVE: 'Salva',
  ACTIONS_FOOTER_UPDATE: 'Aggiorna',
  ACTIONS_FOOTER_CANCEL: 'Annulla',

  VALIDATIONS_PATTERN_DATE: 'Data non valida',
  VALIDATIONS_REQUIRED: 'Campo richiesto',

  MGU_BREADCRUMB_HOME: 'Home',
  MGU_BREADCRUMB_ADMIN: 'Amministrazione',
  MGU_BREADCRUMB_ORGANITATIONS_MANAGE: 'Gestione Organizzazioni',
  MGU_BREADCRUMB_DELEGHE_MANAGE: 'Gestione Deleghe',
  MGU_BREADCRUMB_NEW: 'Nuova organizzazione',
  MGU_BREADCRUMB_EDIT: 'Modifica organizzazione',
  MGU_BREADCRUMB_ASSOCIATE_USERS: 'Associa utenti',
  MGU_ORGANITATIONS_LIST_TITLE: 'Lista organizzazioni',
  MGU_DELEGHE_LIST_TITLE: 'Lista Deleghe',

  MGU_ORGANITATIONS_LIST_BUTTON_NEW: 'Nuova organizzazione',
  MGU_ORGANITATIONS_LIST_BUTTON_FILTER: 'Filtra organizzazione',
  MGU_USERS_LIST_BUTTON_FILTER: 'Filtra utente',
  MGU_PROCEDIMENTI_LIST_BUTTON_FILTER: 'Filtra procedimenti',
  MGU_ORGANITATIONS_LIST_FILTER_FORM_CATEGORY_LABEL: 'Categoria',
  MGU_ORGANITATIONS_LIST_FILTER_FORM_CATEGORY_PLACEHOLDER:
    'Seleziona categoria',
  MGU_ORGANITATIONS_LIST_FILTER_FORM_FISCAL_CODE_LABEL: 'Codice Fiscale',
  MGU_ORGANITATIONS_LIST_FILTER_FORM_FISCAL_CODE_PLACEHOLDER:
    'Inserisci codice fiscale',
  MGU_ORGANITATIONS_LIST_FILTER_FORM_OFFICE_CODE_LABEL: 'Codice ufficio',
  MGU_ORGANITATIONS_LIST_FILTER_FORM_OFFICE_CODE_PLACEHOLDER:
    'Inserisci codice ufficio',
  MGU_ORGANITATIONS_LIST_FILTER_FORM_NAME_LABEL: 'Denominazione',
  MGU_ORGANITATIONS_LIST_FILTER_FORM_NAME_PLACEHOLDER:
    'Inserisci denominazione',
  MGU_ORGANITATIONS_LIST_FILTER_FORM_PIVA_LABEL: 'Partita Iva',
  MGU_ORGANITATIONS_LIST_FILTER_FORM_PIVA_PLACEHOLDER: 'Inserisci partita iva',

  MGU_ORGANITATIONS_LIST_COLUMNS_NAME: 'Denominazione',
  MGU_ORGANITATIONS_LIST_COLUMNS_CATEGORY: 'Categoria',
  MGU_ORGANITATIONS_LIST_COLUMNS_FISCAL_CODE: 'Codice Fiscale',
  MGU_ORGANITATIONS_LIST_COLUMNS_OFFICE_CODE: 'Cod. ufficio',

  MGU_ORGANITATIONS_LIST_DELETE_POP_CONFIRM_TITLE: 'Rimuovi organizzazione',
  MGU_ORGANITATIONS_LIST_DELETE_POP_CONFIRM_TEXT:
    'Confermi di voler rimuovere l\'organizzazione selezionata?',

  MGU_ORGANITATIONS_LIST_DELETE_ERROR_TITLE: 'Errore',
  MGU_ORGANITATIONS_LIST_DELETE_ERROR_TEXT:
    'Non è stato possibile eliminare l\'organizzazione selezionata, riprova più tardi',

  MGU_ORGANITATIONS_LIST_DELETE_SUCCESS_TITLE: 'Organizzazione eliminata',
  MGU_ORGANITATIONS_LIST_DELETE_SUCCESS_TEXT:
    'L\'organizzazione selezionata è stata correttamente eliminata',

  MGU_ORGANITATIONS_LIST_MODAL_MOVE_ORGANITATION_TITLE: 'Sposta organizzazione',
  MGU_ORGANITATIONS_LIST_MODAL_MOVE_ORGANITATION_NAME: 'Denominazione',
  MGU_ORGANITATIONS_LIST_MODAL_MOVE_ORGANITATION_CODE: 'Codice ufficio',
  MGU_ORGANITATIONS_LIST_MODAL_MOVE_ORGANITATION_TEXT:
    'Inserisci l\'organizzazione in',
  MGU_ORGANITATIONS_LIST_MODAL_MOVE_ORGANITATION_ERROR_TITLE: 'Errore',
  MGU_ORGANITATIONS_LIST_MODAL_MOVE_ORGANITATION_ERROR_TEXT:
    'Non è stato possibile spostare l\'organizzazione, riprova più tardi',

  MGU_ORGANITATIONS_LIST_MODAL_MOVE_ORGANITATION_SUCCESS_TITLE:
    'Organizzazione spostata',
  MGU_ORGANITATIONS_LIST_MODAL_MOVE_ORGANITATION_SUCCESS_TEXT:
    'L\'organizzazione selezionata è stata correttamente spostata',

  MGU_ORGANITATIONS_DETAIL_HEADER_CATEGORY: 'Categoria',
  MGU_ORGANITATIONS_DETAIL_HEADER_FISCAL_CODE: 'Codice Fiscale',
  MGU_ORGANITATIONS_DETAIL_HEADER_OFFICE_CODE: 'Codice Ufficio',
  MGU_ORGANITATIONS_DETAIL_HEADER_PIVA: 'Partita IVA',
  MGU_ORGANITATIONS_DETAIL_HEADER_PHONE: 'Telefono',
  MGU_ORGANITATIONS_DETAIL_HEADER_MOBILE_PHONE: 'Cellulare',
  MGU_ORGANITATIONS_DETAIL_HEADER_PEC: 'PEC',
  MGU_ORGANITATIONS_DETAIL_TABS_ORGANIZATION_CHART: 'Organigramma',
  MGU_ORGANITATIONS_DETAIL_TABS_ORGANIZATION_USERS: 'Utenti associati',
  MGU_ORGANITATIONS_DETAIL_TABS_ORGANIZATION_ORGANIZATION_DATA:
    'Dati organizzazione',
  MGU_ORGANITATIONS_DETAIL_TABS_ORGANIZATION_ORGANIZATION_DATA_CONTACTS:
    'Contatti',
  MGU_ORGANITATIONS_DETAIL_TABS_ORGANIZATION_ORGANIZATION_DATA_ADDRESS:
    'Indirizzo',
  MGU_ORGANITATIONS_DETAIL_TABS_ORGANIZATION_ORGANIZATION_DATA_CAP: 'CAP',
  MGU_ORGANITATIONS_DETAIL_TABS_ORGANIZATION_ORGANIZATION_DATA_CITY: 'Comune',
  MGU_ORGANITATIONS_DETAIL_TABS_ORGANIZATION_ORGANIZATION_DATA_PROVINCE:
    'Provincia',
  MGU_ORGANITATIONS_DETAIL_TABS_ORGANIZATION_ORGANIZATION_DATA_EMAIL: 'Email',
  MGU_ORGANITATIONS_DETAIL_TABS_ORGANIZATION_ORGANIZATION_DATA_PEC: 'PEC',
  MGU_ORGANITATIONS_DETAIL_TABS_ORGANIZATION_ORGANIZATION_DATA_CODE_NODE:
    'Codice nodo pagamenti',

  MGU_ORGANITATIONS_DETAIL_BUTTONS_TO_LIST: 'Torna alla lista',
  MGU_ORGANITATIONS_DETAIL_BUTTONS_NEW_ORGANIZATION: 'Nuova organizzazione',

  MGU_ORGANITATIONS_NEW_TITLE: 'Nuova organizzazione',
  MGU_ORGANITATIONS_NEW_TABS_INTERNAL_ORGANIZATIONS: 'Organizzazioni interne',

  MGU_ORGANITATIONS_NEW_TABS_USERS: 'Utenti associati',
  MGU_ORGANITATIONS_NEW_TABS_USERS_BUTTONS_ASSOCIATE: 'Associa utenti',
  // MGU_ORGANITATIONS_NEW_TABS_USERS_AT_LEAST_ONE_TITLE: 'Associazione utenti',
  // MGU_ORGANITATIONS_NEW_TABS_USERS_AT_LEAST_ONE_MESSAGE: 'Selezionare almeno un utente',
  MGU_ORGANITATIONS_NEW_TABS_DATA: 'Dati organizzazione',
  MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_GENERAL_DATA_NAME_LABEL:
    'Denominazione',
  MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_GENERAL_DATA_NAME_PLACEHOLDER:
    'Inserisci nome organizzazione',
  MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_GENERAL_DATA_CATEGORY_LABEL:
    'Categoria',
  MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_GENERAL_DATA_CATEGORY_PLACEHOLDER:
    'Scegli categoria',
  MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_GENERAL_DATA_FISCAL_CODE_LABEL:
    'Codice Fiscale',
  MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_GENERAL_DATA_FISCAL_CODE_PLACEHOLDER:
    'Inserisci CF ente',
  MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_GENERAL_DATA_FISCAL_CODE_ERROR:
    'Codice fiscale non valido',
  MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_GENERAL_DATA_OFFICE_CODE_LABEL:
    'Cod. ufficio',
  MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_GENERAL_DATA_PIVA_CF_ERROR:
    'Inserisci almeno uno tra codice fiscale e partita iva',
  MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_GENERAL_DATA_OFFICE_CODE_PLACEHOLDER:
    'Inserisci codice ufficio',
  MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_GENERAL_DATA_PIVA_LABEL: 'Partita IVA',
  MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_GENERAL_DATA_PIVA_PLACEHOLDER:
    'Inserisci P.IVA ente',
  MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_GENERAL_DATA_PIVA_ERROR:
    'P.IVA non valida',
  MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_GENERAL_DATA_CAP_ERROR:
    'CAP non valido',
  MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_CONTACTS_DATA_TITLE: 'Contatti',
  MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_CONTACTS_DATA_ADDRESS_LABEL:
    'Indirizzo',
  MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_CONTACTS_DATA_ADDRESS_PLACEHOLDER:
    'Inserisci via/piazza e n. Civico',
  MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_CONTACTS_DATA_CAP_LABEL: 'CAP',
  MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_CONTACTS_DATA_CAP_PLACEHOLDER:
    'Inserisci CAP',
  MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_CONTACTS_DATA_CITY_LABEL: 'Comune',
  MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_CONTACTS_DATA_CITY_PLACEHOLDER:
    'Inserisci comune',
  MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_CONTACTS_DATA_PROVINCE_LABEL:
    'Provincia',
  MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_CONTACTS_DATA_PROVINCE_PLACEHOLDER:
    'Inserisci provincia',
  MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_CONTACTS_DATA_PHONE_LABEL: 'Telefono',
  MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_CONTACTS_DATA_PHONE_PLACEHOLDER:
    'Inserisci n.linea fissa',
  MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_CONTACTS_DATA_PHONE_ERROR:
    'Numero telefono fisso non valido',
  MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_CONTACTS_DATA_MOBILE_PHONE_LABEL:
    'Cellulare',
  MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_CONTACTS_DATA_MOBILE_PHONE_PLACEHOLDER:
    'Inserisci n. Cellulare',
  MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_CONTACTS_DATA_EMAIL_LABEL: 'Email',
  MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_CONTACTS_DATA_EMAIL_PLACEHOLDER:
    'Inserisci email',
  MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_CONTACTS_DATA_PEC_LABEL: 'PEC',
  MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_CONTACTS_DATA_PEC_PLACEHOLDER:
    'Inserisci indirizzo PEC',
  MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_CONTACTS_DATA_PAYMENT_NODE_LABEL:
    'Codice nodo pagamenti',
  MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_CONTACTS_DATA_PAYMENT_NODE_PLACEHOLDER:
    'Inserisci nodo',

  MGU_ORGANITATIONS_DETAIL_FETCH_ERROR_TITLE: 'Errore',
  MGU_ORGANITATIONS_DETAIL_FETCH_ERROR_TEXT:
    'C\'è stato un errore nel recuperare i dati dell\'organizzazione, riprova più tardi',

  MGU_ORGANITATIONS_NEW_SUCCESS_TITLE: 'Organizzazione creata',
  MGU_ORGANITATIONS_NEW_SUCCESS_TEXT:
    'La nuova organizzazione è stata correttamente salvata',
  MGU_ORGANITATIONS_NEW_ERROR_TITLE: 'Errore',
  MGU_ORGANITATIONS_NEW_ERROR_TEXT:
    'Non è stato possibile salvare la nuova organizzazione, riprova più tardi',
  MGU_ORGANITATIONS_EDIT_SUCCESS_TITLE: 'Organizzazione modificata',
  MGU_ORGANITATIONS_EDIT_SUCCESS_TEXT:
    'I dati dell\'organizzazione sono stati correttamente modificati',
  MGU_ORGANITATIONS_EDIT_ERROR_TITLE: 'Errore',
  MGU_ORGANITATIONS_EDIT_ERROR_TEXT:
    'Non è stato possibile salvare le modifiche, riprova più tardi',

  MGU_ORGANITATIONS_EDIT_SUCCESS_TAB_ORGANIGRAMMA_TITLE:
    'Organizzazione modificata',
  MGU_ORGANITATIONS_EDIT_SUCCESS_TAB_ORGANIGRAMMA_TEXT:
    'La struttura delle organizzazioni interne sono state correttamente modificate',
  MGU_ORGANITATIONS_EDIT_ERROR_TAB_ORGANIGRAMMA_TITLE: 'Errore',
  MGU_ORGANITATIONS_EDIT_ERROR_TAB_ORGANIGRAMMA_TEXT:
    'Non è stato possibile salvare le modifiche alla struttura delle organizzazioni interne, riprova più tardi',

  MGU_ORGANITATIONS_EDIT_ERROR_TAB_USERS_TITLE: 'Errore',
  MGU_ORGANITATIONS_EDIT_ERROR_TAB_USERSL_TEXT:
    'Non è stato possibile salvare le modifiche riguardanti gli utenti, riprova più tardi',
  MGU_ORGANITATIONS_EDIT_SUCCESS_TAB_USERS_TITLE: 'Utenti modificati',
  MGU_ORGANITATIONS_EDIT_SUCCESS_TAB_USERS_TEXT:
    'I dati riguardanti gli utenti sono stati correttamente salvati',

  MGU_ORGANITATIONS_ASSOCIATE_USERS_TITLE: 'Associa utenti',
  MGU_ORGANITATIONS_ASSOCIATE_USERS_MODAL_DELETE_TITLE:
    'Rimuovi utente da organizzazione',
  MGU_ORGANITATIONS_ASSOCIATE_USERS_MODAL_DELETE_TEXT:
    'Confermi di voler rimuovere l’utente dall’organizzazione?',

  MGU_INCARDINAMENTO_MODAL_TITLE: 'Inserisci dati utente',
  MGU_INCARDINAMENTO_MODAL_FORM_EMAIL: 'Email',
  MGU_INCARDINAMENTO_MODAL_FORM_EMAIL_PLACEHOLER: 'Email',
  MGU_INCARDINAMENTO_MODAL_FORM_HR: 'Utente risorse umane',
  MGU_INCARDINAMENTO_MODAL_FORM_HR_PLACEHOLER: 'Indica se è un utente HR',
  MGU_INCARDINAMENTO_MODAL_FORM_FUNZIONE_PLACEHOLDER: 'Inserisci funzione',
  MGU_INCARDINAMENTO_MODAL_FORM_FUNZIONE: 'Funzione',
  MGU_INCARDINAMENTO_MODAL_FORM_NOTIFICHE_ACCESSO_PLACEHOLDER:
    'Notifiche accesso',
  MGU_INCARDINAMENTO_MODAL_FORM_NOTIFICHE_ACCESSO: 'Notifiche accesso',
  MGU_INCARDINAMENTO_MODAL_FORM_PEC: 'Pec',
  MGU_INCARDINAMENTO_MODAL_FORM_PEC_PLACEHOLDER: 'Pec',
  MGU_INCARDINAMENTO_MODAL_FORM_PHONE: 'Telefono',
  MGU_INCARDINAMENTO_MODAL_FORM_PHONE_PLACEHOLDER: 'Telefono',
  MGU_INCARDINAMENTO_MODAL_FORM_PHONE_FORMAT_ERROR:
    'Inserire numero di telefono valido',
  MGU_INCARDINAMENTO_MODAL_FORM_FILTER_FUNZIONE: 'Funzione',
  MGU_INCARDINAMENTO_MODAL_FORM_FILTER_FUNZIONE_PLACEHOLER:
    'Seleziona una funzione',
  MGU_INCARDINAMENTO_MODAL_FORM_FILTER_VISIBILITA_RUBRICA:
    'Visibilità in rubrica',
  MGU_INCARDINAMENTO_MODAL_FORM_FILTER_VISIBILITA_RUBRICA_PLACEHOLER:
    'Seleziona un valore',
  MGU_INCARDINAMENTO_MODAL_FORM_ABILITATO_BACKOFFICE: 'Abilitato al backoffice',
  MGU_INCARDINAMENTO_MODAL_FORM_ABILITATO_BACKOFFICE_PLACEHOLER:
    'Seleziona un valore',
  MGU_INCARDINAMENTO_MODAL_FORM_FILTER_VISIBILITA_RUBRICA_ABILITATO:
    'Abilitato',
  MGU_INCARDINAMENTO_MODAL_FORM_FILTER_VISIBILITA_RUBRICA_DISABILITATO:
    'Disabilitato',
  MGU_INCARDINAMENTO_MODAL_FORM_FILTER_CATEGORIA: 'Categoria',
  MGU_INCARDINAMENTO_MODAL_FORM_FILTER_CATEGORIA_PLACEHOLER:
    'Seleziona una categoria',
  MGU_INCARDINAMENTO_MODAL_FORM_START_DATE: 'Data Inizio Validità',
  MGU_INCARDINAMENTO_MODAL_FORM_END_DATE: 'Data Fine Validità',
  MGU_INCARDINAMENTO_EMAIL_FORMAT_ERROR: 'Indirizzo non valido',
  MGU_INCARDINAMENTO_EMAIL_OR_PEC_REQUIRED: 'Inserisci email o pec',
  MGU_PROCEDIMENTO_MODAL_FORM_FILTER_RUOLO: 'Ruolo',
  MGU_PROCEDIMENTO_MODAL_FORM_FILTER_RUOLO_PLACEHOLER: 'Inserisci il ruolo',
  MGU_PROCEDIMENTO_MODAL_FORM_FILTER_DEFAULT_USER:
    'Assegnare il ruolo di default',

  MGU_USER_DETAIL_INCARDINAMENTO_DELETE_TITLE:
    'Rimuovi utente da organizzazione',
  MGU_USER_DETAIL_INCARDINAMENTO_DELETE_TEXT:
    'Confermi di voler rimuovere l’utente dall’organizzazione?',
  MGU_USER_DETAIL_PROCEDIMENTO_DELETE_TITLE: 'Rimuovi utente dal procedimento',
  MGU_USER_DETAIL_PROCEDIMENTO_DELETE_TEXT:
    'Confermi di voler rimuovere l’utente dal procedimento?',

  MGU_ORGANITATIONS_LIST_EXPORT_SUCCESS_TITLE: 'Operazione completata',
  MGU_ORGANITATIONS_LIST_EXPORT_SUCCESS_TEXT: 'File esportato con successo',

  MGU_ORGANITATIONS_LIST_EXPORT_DELETE_TITLE: 'Errore',
  MGU_ORGANITATIONS_LIST_EXPORT_DELETE_TEXT:
    'Non è stato possibile esportare i risultati trovati, riprova più tardi',

  // ROLE SECTION
  MGU_ROLE_BREADCRUMB_HOME: 'Home',
  MGU_ROLE_BREADCRUMB_ADMIN: 'Amministrazione',
  MGU_ROLE_BREADCRUMB_ROLE: 'Ruoli',
  MGU_ROLE_BREADCRUMB_VERTICALI_ROLES: 'Verticali',
  MGU_VERTICALI_LIST_TITLE: 'Lista verticali',
  MGU_ROLE_LIST_TITLE: 'Lista ruoli',
  MGU_ROLE_LIST_SUBTITLE: 'risultati trovati',
  MGU_VERTICALI_LIST_SUBTITLE: 'risultati trovati',
  MGU_ROLE_LIST_FILTER_BUTTON: 'Filtra ruoli',
  MGU_ROLE_LIST_FILTER_FORM_CODE_LABEL: 'Codice ruolo',
  MGU_ROLE_LIST_FILTER_FORM_CODE_PLACEHOLDER: 'Cerca per codice',
  MGU_ROLE_LIST_FILTER_FORM_DESCRIPTION_LABEL: 'Nome ruolo',
  MGU_ROLE_LIST_FILTER_FORM_DESCRIPTION_PLACEHOLDER: 'Cerca per nome',
  MGU_ROLE_LIST_COLUMNS_DESCRIPTION: 'Nome ruolo',
  MGU_VERTICALE_LIST_COLUMNS_DESCRIPTION: 'Nome verticale',
  MGU_ROLE_LIST_COLUMNS_CODE: 'Codice ruolo',
  MGU_VERTICALE_LIST_COLUMNS_CODE: 'Codice verticale',
  MGU_ROLE_LIST_FETCH_ERROR_TITLE: 'Errore',
  MGU_ROLE_LIST_FETCH_ERROR_TEXT:
    'Non è stato possibile recuperare i tag, riprova più tardi',
  MGU_ROLE_TOASTR_ERROR_ROLE_DETAIL_TITLE: 'Errore',
  MGU_ROLE_TOASTR_ERROR_ROLE_DETAIL_MESSAGE:
    'C\'è stato un problema nel recuperare i dati del tag, riprova piu tardi',
  MGU_ROLE_DETAIL_CODE: 'Codice ruolo',
  MGU_ROLE_DETAIL_USER_NUMBER: 'N. Utenti associati',
  MGU_ROLE_DETAIL_BUTTONS_TO_LIST: 'Torna alla lista',
  MGU_ROLE_MODIFICATION_ROLE: 'Modifica Ruoli',

  MGU_USER_BREADCRUMB_HOME: 'Home',
  MGU_USER_BREADCRUMB_ADMIN: 'Amministrazione',
  MGU_USER_BREADCRUMB_USER: 'Gestione utenti',
  MGU_USER_BREADCRUMB_PROFILO: 'Gestione profilo',
  MGU_USER_DETAIL_FISCAL_CODE: 'Codice fiscale',
  MGU_USER_DETAIL_TELEPHONE: 'Telefono',
  MGU_USER_DETAIL_CELLULARAE: 'Cellulare',
  MGU_USER_DETAIL_EMAIL: 'Email',
  MGU_USER_DETAIL_PEC: 'Pec',
  MGU_USER_DETAIL_BUTTONS_TO_LIST: 'Torna alla lista',
  MGU_USER_DETAIL_ADDRESS: 'Indirizzo',
  MGU_USER_DETAIL_MUNICIPALITY: 'Comune',
  MGU_USER_DETAIL_POSTAL_CODE: 'CAP',
  MGU_USER_DETAIL_ROLE: 'Ruoli',
  MGU_USER_DETAIL_DATI_DIPENDENTE_TITLE: 'Dati dipendente',
  MGU_USER_DETAIL_DATI_DIPENDENTE_EMAIL: 'Email',
  MGU_USER_DETAIL_DATI_DIPENDENTE_PEC: 'Pec',
  MGU_USER_DETAIL_DATI_DIPENDENTE_PHONE: 'N.Telefono',
  MGU_USER_DETAIL_DATI_DIPENDENTE_CATEGORIA: 'Categoria',
  MGU_USER_DETAIL_DATI_DIPENDENTE_VISIBILITA_RUBRICA: 'Visibilità in rubrica',
  MGU_USER_DETAIL_DATI_DIPENDENTE_ABILITATO_BACKOFFICE:
    'Abilitato al backoffice',
  MGU_USER_DETAIL_DATI_DIPENDENTE_HR: 'Utente risorse umane',
  MGU_USER_DETAIL_DATI_DIPENDENTE_FUNZIONE: 'Funzione',
  MGU_USER_DETAIL_DATI_NOTIFICHE_ACCESSO: 'Notifiche accesso',
  MGU_USER_DETAIL_DATI_DIPENDENTE_DATA_INIZIO_VALIDITA: 'Data inizio validità',
  MGU_USER_DETAIL_DATI_DIPENDENTE_DATA_FINE_VALIDITA: 'Data fine validità',
  MGU_USER_DETAIL_ORGANIZATION: 'Organizzazioni',
  MGU_USER_DETAIL_ORGANIZATION_NAME: 'Organizzazione',
  MGU_USER_DETAIL_ORGANIZATION_OFFICE_CODE: 'Cod. ufficio',
  MGU_USER_DETAIL_ORGANIZATION_FUNCTION: 'Funzione',
  MGU_USER_DETAIL_PROCEDIMENTI: 'Procedimenti',
  MGU_USER_DETAIL_PROCEDIMENTI_NAME: 'Nome procedimento',
  MGU_USER_DETAIL_PROCEDIMENTI_CODE: 'Codice',
  MGU_USER_DETAIL_PROCEDIMENTI_CATEGORIES: 'Categoria',
  MGU_USER_DETAIL_PROCEDIMENTI_STATE: 'Stato',
  MGU_USER_DETAIL_PROCEDIMENTI_ROLE: 'Ruolo',
  MGU_USER_DETAIL_USERNAME: 'Nome utente',
  MGU_USER_DETAIL_USERNAME_PLACEHOLDER: 'Inserisci nome utente',
  MGU_USER_DETAIL_USERNAME_SECTION_TITLE: 'Associazione utenza SUA',
  MGU_USER_DETAIL_USERNAME_DIALOG_TITLE: 'Associazione utenza SUA',
  MGU_USER_DETAIL_USERNAME_DIALOG_DONE_TITLE: 'Utente creato con successo',
  MGU_USER_DETAIL_USERNAME_DIALOG_DONE_TEXT:
    'È stata inviata una mail al dipendente',
  MGU_USER_DETAIL_USERNAME_BUTTON: 'Crea utente SUA',
  MGU_USER_DETAIL_PROCEDIMENTI_DATE_TO: 'Data inizio validità',
  MGU_USER_DETAIL_PROCEDIMENTI_DATE_END: 'Data fine validità',
  MGU_USER_DETAIL_BUTTONS_MANAGE_PROCEDIMENTI: 'Gestisci Procedimenti',
  PROFILE_FORM_INPUT_USERNAME_LABEL: 'Username',
  PROFILE_FORM_INPUT_USERNAME_PLACEHOLDER: 'Inserisci username',
  MGU_USER_DETAIL_BUTTONS_MANAGE_ROLE: 'Gestisci ruoli',
  MGU_USER_DETAIL_BUTTONS_MANAGE_DATI_DIPENDENTE: 'Gestisci dati dipendente',
  MGU_USER_DETAIL_BUTTONS_MANAGE_ORGANIZATIONS: 'Gestisci organizzazioni',
  MGU_USER_DETAIL_INCARICHI: 'Incarichi',
  MGU_USER_DETAIL_BUTTONS_MANAGE_INCARICHI: 'Inserisci incarico',
  MGU_INCARICO_MODAL_TITLE: 'Inserisci dati incarico',
  MGU_UTENTE_INCARICHI_EDIT_MODAL_FORM_INPUT_TIPO_INCARICO_LABEL:
    'Tipo incarico',
  MGU_UTENTE_INCARICHI_EDIT_MODAL_FORM_INPUT_TIPO_INCARICO_PLACEHOLDER:
    'Scegli tipo incarico',
  MGU_UTENTE_INCARICHI_EDIT_MODAL_FORM_INPUT_PORTO_RIFERIMENTO_LABEL:
    'Porto di riferimento',
  MGU_UTENTE_INCARICHI_EDIT_MODAL_FORM_INPUT_PORTO_RIFERIMENTO_PLACEHOLDER:
    'Scegli porto di riferimento',
  MGU_UTENTE_INCARICHI_EDIT_MODAL_FORM_BEGIN_VALIDITY_LABEL: 'Inizio validità',
  MGU_UTENTE_INCARICHI_EDIT_MODAL_FORM_BEGIN_VALIDITY_PLACEHOLDER:
    'Inserisci data di inizio validità',
  MGU_UTENTE_INCARICHI_EDIT_MODAL_FORM_END_VALIDITY_LABEL: 'Fine validità',
  MGU_UTENTE_INCARICHI_EDIT_MODAL_FORM_END_VALIDITY_PLACEHOLDER:
    'Inserisci data di fine validità',
  MGU_USER_DETAIL_INCARICHI_NAME: 'Nome incarico',
  MGU_USER_DETAIL_INCARICHI_TIPO_INCARICO: 'Tipo incarico',
  MGU_USER_DETAIL_INCARICHI_PORTO_RIFERIMENTO: 'Porto di riferimento',
  MGU_USER_DETAIL_INCARICHI_DATE_TO: 'Data inizio validità',
  MGU_USER_DETAIL_INCARICHI_DATE_END: 'Data fine validità',
  MGU_USER_LIST_TITLE: 'Lista utenti',
  MGU_USER_LIST_SUBTITLE: 'risultati trovati',
  MGU_USER_LIST_FILTER_BUTTON: 'Filtra utenti',
  MGU_USER_LIST_FILTER_FORM_FISCAL_CODE_LABEL: 'Codice fiscale',
  MGU_USER_LIST_FILTER_FORM_FISCAL_CODE_PLACEHOLDER: 'Cerca per CF utente',
  MGU_USER_LIST_FILTER_FORM_NAME_LABEL: 'Nome',
  MGU_USER_LIST_FILTER_FORM_NAME_PLACEHOLDER: 'Cerca per nome utente',
  MGU_USER_LIST_FILTER_FORM_SURNAME_LABEL: 'Cognome',
  MGU_USER_LIST_FILTER_FORM_SURNAME_PLACEHOLDER: 'Cerca per cognome utente',
  MGU_USER_LIST_FILTER_FORM_ORGANIZATION_LABEL: 'Azienda di appartenenza',
  MGU_USER_LIST_FILTER_FORM_ORGANIZATION_PLACEHOLDER: 'Cerca per azienda',
  MGU_USER_LIST_COLUMNS_USERNAME: 'Nome utente',
  MGU_USER_LIST_COLUMNS_FISCAL_CODE: 'Codice fiscale',
  MGU_USER_LIST_COLUMNS_TELEPHONE: 'Telefono',
  MGU_USER_LIST_COLUMNS_EMAIL: 'Email',
  MGU_USER_LIST_COLUMNS_ACTIONS: 'Azioni',
  MGU_USER_LIST_COLUMNS_DATE_TO: 'Data inizio validità',
  MGU_USER_LIST_COLUMNS_DATE_END: 'Data fine validità',
  MGU_USER_LIST_BUTTON_EXPORT: 'Esporta elenco',

  MGU_USER_LIST_FETCH_ERROR_TITLE: 'Errore',
  MGU_USER_LIST_FETCH_ERROR_TEXT:
    'Non è stato possibile recuperare gli utenti, riprova più tardi',
  MGU_USER_TOASTR_ERROR_USER_DETAIL_TITLE: 'Errore',
  MGU_USER_TOASTR_ERROR_USER_DETAIL_MESSAGE:
    'C\'è stato un problema nel recuperare i dati dell\'utente, riprova piu tardi',
  MGU_USER_LIST_TOASTR_SUCCESS_EXPORT_TITLE: 'Operazione completata',
  MGU_USER_LIST_TOASTR_SUCCESS_EXPORT_MESSAGE: 'File esportato con successo',
  MGU_USER_LIST_TOASTR_ERROR_EXPORT_TITLE: 'Errore',
  MGU_USER_LIST_TOASTR_ERROR_EXPORT_MESSAGE:
    'C\'è stato un problema nell\'export della lista utenti',
  MGU_USER_SET_ROLE_BREADCRUMB_SET_ROLE: 'Associa ruoli',
  MGU_USER_SET_ROLE_TITLE: 'Associa ruoli',
  MGU_USER_SET_ROLE_COLUMNS_ROLE: 'Ruolo',
  MGU_USER_SET_ROLE_COLUMNS_VERTICALE: 'Verticale',
  MGU_USER_SET_ROLE_TEXT:
    'Seleziona i ruoli da associare all’utente e premi Salva',
  MGU_USER_SET_ROLE_FOOTER_CANCEL_BUTTON: 'Annulla',
  MGU_USER_SET_ROLE_FOOTER_SAVE_BUTTON: 'Salva',
  MGU_USER_SET_ROLE_TOASTR_SUCCESS_TITLE: 'Operazione completata',
  MGU_USER_SET_ROLE_TOASTR_SUCCESS_TEXT: 'Ruoli associati con successo',
  MGU_USER_SET_ROLE_TOASTR_ERROR_TITLE: 'Errore',
  MGU_USER_SET_ROLE_TOASTR_ERROR_TEXT:
    'Si è verificato un problema nell\'associazione dei ruoli, riprova più tardi',

  MGU_USER_SET_ORGANIZATION_BREADCRUMB_SET_ORGANIZATION:
    'Associa organizzazioni',

  MGU_USER_SET_ORGANIZATION_BREADCRUMB_SET_PROCEDIMENTI: 'Associa procedimenti',
  MGU_USER_SET_ORGANIZATION_TITLE: 'Associa organizzazioni',
  MGU_USER_SET_PROCEDIMENTI_TITLE: 'Associa procedimenti',
  MGU_USER_SET_ORGANIZATION_ERROR_TITLE: 'Errore',
  MGU_USER_SET_ORGANIZATION_ERROR_TEXT:
    'Si è verificato un problema nell\'associazione delle organizzazioni, riprova più tardi',
  MGU_USER_SET_ORGANIZATION_SUCCESS_TITLE: 'Operazione completata',
  MGU_USER_SET_ORGANIZATION_SUCCESS_TEXT:
    'Organizzazioni associate con successo',

  MGU_USER_DELETE_ORGANIZATION_ERROR_TITLE: 'Errore',
  MGU_USER_DELETE_ORGANIZATION_ERROR_TEXT:
    'Si è verificato un problema nella rimozione dell\'organizzazione, riprova più tardi',
  MGU_USER_DELETE_ORGANIZATION_SUCCESS_TITLE: 'Operazione completata',
  MGU_USER_DELETE_ORGANIZATION_SUCCESS_TEXT:
    'Organizzazione rimossa con successo',

  MGU_USER_UPDATE_ORGANIZATION_ERROR_TITLE: 'Errore',
  MGU_USER_UPDATE_ORGANIZATION_ERROR_TEXT:
    'Si è verificato un problema nell\'aggiornamento dell\'organizzazione, riprova più tardi',
  MGU_USER_UPDATE_ORGANIZATION_SUCCESS_TITLE: 'Operazione completata',
  MGU_USER_UPDATE_ORGANIZATION_SUCCESS_TEXT:
    'Organizzazione aggiornata con successo',

  MGU_DATI_DIPENDENTE_UPDATE_SUCCESS_TITLE: 'Operazione completata',
  MGU_DATI_DIPENDENTE_UPDATE_SUCCESS_TEXT:
    'Dati dipendente aggiornati con successo',

  MGU_USER_UPDATE_PROCEDIMENTO_ERROR_TITLE: 'Errore',
  MGU_USER_UPDATE_PROCEDIMENTO_ERROR_TEXT:
    'Si è verificato un problema nell\'aggiornamento del procedimento, riprova più tardi',
  MGU_USER_UPDATE_PROCEDIMENTO_SUCCESS_TITLE: 'Operazione completata',
  MGU_USER_UPDATE_PROCEDIMENTO_SUCCESS_TEXT:
    'Procedimento aggiornato con successo',

  MGU_USER_DELETE_PROCEDIMENTO_ERROR_TITLE: 'Errore',
  MGU_USER_DELETE_PROCEDIMENTO_ERROR_TEXT:
    'Si è verificato un problema nella rimozione del procedimento, riprova più tardi',
  MGU_USER_DELETE_PROCEDIMENTO_SUCCESS_TITLE: 'Operazione completata',
  MGU_USER_DELETE_PROCEDIMENTO_SUCCESS_TEXT:
    'Procedimento rimosso con successo',

  MGU_USER_SET_PROCEDIMENTI_ERROR_TITLE: 'Errore',
  MGU_USER_SET_PROCEDIMENTI_ERROR_TEXT:
    'Si è verificato un problema nell\'associazione dei procedimenti, riprova più tardi',
  MGU_USER_SET_PROCEDIMENTI_SUCCESS_TITLE: 'Operazione completata',
  MGU_USER_SET_PROCEDIMENTI_SUCCESS_TEXT: 'Procedimenti associati con successo',

  MGU_DELEGHE_LIST_BUTTON_FILTER: 'Filtra deleghe',
  MGU_TABLE_COLUMN_NOME_RICHIEDENTE: 'Nome Delegante',
  MGU_TABLE_COLUMN_NOME_DELEGATO: 'Nome Delegato',
  MGU_TABLE_COLUMN_TIPO: 'Tipo Richiedente',
  MGU_TABLE_COLUMN_STATO: 'Stato',
  MGU_TABLE_COLUMN_FINE_VALIDITA: 'Fine validità',

  SCRIVANIA_FORM_FILTER_DELEGHE_TIPO_RICHIEDENTE_LABEL: 'Tipo Richiedente',
  SCRIVANIA_FORM_FILTER_DELEGHE_TIPO_RICHIEDENTE_PLACEHOLDER:
    'Cerca per tipo richiedente',
  SCRIVANIA_FORM_FILTER_DELEGHE_NOME_RICHIEDENTE_LABEL: 'Nome Richiedente',
  SCRIVANIA_FORM_FILTER_DELEGHE_NOME_RICHIEDENTE_PLACEHOLDER:
    'Cerca per nome richiedente',
  SCRIVANIA_FORM_FILTER_DELEGHE_NOME_GIU_RICHIEDENTE_LABEL:
    'Denominazione Richiedente',
  SCRIVANIA_FORM_FILTER_DELEGHE_NOME_GIU_RICHIEDENTE_PLACEHOLDER:
    'Cerca per denominazione richiedente',
  SCRIVANIA_FORM_FILTER_DELEGHE_COGNOME_RICHIEDENTE_LABEL:
    'Cognome Richiedente',
  SCRIVANIA_FORM_FILTER_DELEGHE_COGNOME_RICHIEDENTE_PLACEHOLDER:
    'Cerca per cognome richiedente',
  SCRIVANIA_FORM_FILTER_DELEGHE_CF_RICHIEDENTE_LABEL:
    'Codice Fiscale Richiedente',
  SCRIVANIA_FORM_FILTER_DELEGHE_CF_RICHIEDENTE_PLACEHOLDER:
    'Cerca per codice fiscale richiedente',
  SCRIVANIA_FORM_FILTER_DELEGHE_STATO_LABEL: 'Stato',
  SCRIVANIA_FORM_FILTER_DELEGHE_STATO_PLACEHOLDER: 'Cerca per stato',
  MGU_FORM_FILTER_DELEGHE_NOME_DELEGATO_LABEL: 'Nome Delegato',
  MGU_FORM_FILTER_DELEGHE_NOME_DELEGATO_PLACEHOLDER: 'Cerca per nome delegato',
  MGU_FORM_FILTER_DELEGHE_COGNOME_DELEGATO_LABEL: 'Cognome Delegato',
  MGU_FORM_FILTER_DELEGHE_COGNOME_DELEGATO_PLACEHOLDER:
    'Cerca per cognome delegato',
  MGU_FORM_FILTER_DELEGHE_CF_DELEGATO_LABEL: 'Codice Fiscale Delegato',
  MGU_FORM_FILTER_DELEGHE_CF_DELEGATO_PLACEHOLDER:
    'Cerca per codice fiscale delegato',
  TOASTR_ERROR_EXPORT_MESSAGE:
    'Impossibile esportare il file richiesto, riprova più tardi',
  TOASTR_SUCCESS_EXPORT_TITLE: 'Operazione completata',
  TOASTR_SUCCESS_EXPORT_MESSAGE: 'File esportato con successo',
  STATE_INSERITA: 'Inserita',
  STATE_VERIFICATA: 'Verificata',
  STATE_RIGETTATA: 'Rigettata',
  STATE_RIGETTATA_PER_ALTRO_PROCEDIMENTO: 'Rigettata per altro procedimento',
  STATE_REVOCATA: 'Revocata',
  STATE_null: '---',
  STATE_BOZZA: 'Bozza',
  STATE_: '---',
  FUNZIONE_null: '---',
  FUNZIONE_: '---',
  FUNZIONE_RICHIEDENTE: 'Richiedente',
  FUNZIONE_CapoUfficio: 'Capo Ufficio',
  FUNZIONE_Amministratore: 'Amministratore',
  FUNZIONE_RappresentanteLegale: 'Rappresentante Legale',
  TIPO_: '---',
  TIPO_PERSONA_FISICA: 'Persona fisica',
  TIPO_PERSONA_GIURIDICA: 'Persona giuridica',
  MGU_BREADCRUMB_DELEGHE_MODIFICA: 'Modifica validità',
  MGU_DELEGA_EDIT_ID: 'ID delega',
  MGU_DELEGA_EDIT_NOME: 'Nome Delegato',
  MGU_DELEGA_EDIT_TIPO: 'Tipo Richiedente',
  MGU_DELEGA_EDIT_FUNZIONE: 'Funzione richiedente',
  MGU_DELEGA_EDIT_TITLE_VALIDITA: 'Validità delega',
  MGU_DELEGA_EDIT_START_VALIDITA: 'Data inizio validità',
  MGU_DELEGA_EDIT_END_VALIDITA: 'Data fine validità',
  MGU_DELEGA_EDIT_STATO: 'Stato',
  MGU_DELEGA_EDIT_BUTTON_VALIDITA: 'Modifica stato/validità',
  MGU_DELEGA_EDIT_TITLE_PROCEDIMENTI: 'Procedimenti',
  MGU_DELEGA_EDIT_TABLE_PROCEDIMENTI_NAME: 'Nome Procedimento',
  MGU_DELEGA_EDIT_TABLE_PROCEDIMENTI_START_VALIDITA: 'Inizio validità',
  MGU_DELEGA_EDIT_TABLE_PROCEDIMENTI_END_VALIDITA: 'Fine validità',
  MGU_DELEGA_EDIT_TABLE_PROCEDIMENTI_TIPO_DELEGA: 'Tipologia delega',
  MGU_DELEGA_EDIT_TABLE_PROCEDIMENTI_STATO: 'Stato',
  MGU_DELEGA_EDIT_MODAL_PROCEDIMENTI_TITLE:
    'Modifica stato procedimento in delega',
  MGU_DELEGA_EDIT_MODAL_PROCEDIMENTI_STATO: 'Stato delega procedimento',
  MGU_DELEGA_MODAL_FORM_MOTIVAZIONE_LABEL: 'Motivazione',
  MGU_DELEGA_MODAL_FORM_MOTIVAZIONE_PLACEHOLDER: 'Inserisci motivazione',

  MGU_DELEGA_EDIT_MODAL_DELEGA_TITLE: 'Modifica stato delega',
  MGU_DELEGA_EDIT_MODAL_DELEGA_STATO: 'Stato delega',
  MGU_DELEGA_EDIT_MODAL_DELEGA_FORM_STATE_LABEL: 'Stato delega',
  MGU_DELEGA_EDIT_MODAL_DELEGA_FORM_STATE_PLACEHOLDER: 'Scegli stato delega',
  MGU_DELEGA_EDIT_MODAL_PROCEDIMENTO_FORM_STATE_LABEL:
    'Stato delega procedimenti',
  MGU_DELEGA_EDIT_MODAL_PROCEDIMENTO_FORM_STATE_PLACEHOLDER:
    'Scegli stato delega procedimento',
  MGU_DELEGA_EDIT_MODAL_FORM_BEGIN_VALIDITY_LABEL: 'Inizio validità',
  MGU_DELEGA_EDIT_MODAL_FORM_BEGIN_VALIDITY_PLACEHOLDER:
    'Inseriscici data inizio validità',
  MGU_DELEGA_EDIT_MODAL_FORM_TIPO_DELEGA_LABEL: 'Tipo delega',
  MGU_DELEGA_EDIT_MODAL_FORM_TIPO_DELEGA_PLACEHOLDER: 'Scegli tipo delega',
  MGU_DELEGA_EDIT_MODAL_FORM_END_VALIDITY_LABEL: 'Fine validità',
  MGU_DELEGA_EDIT_MODAL_FORM_END_VALIDITY_PLACEHOLDER:
    'Inseriscici data fine validità',
  MGU_DELEGA_EDIT_MODAL_TEXT_DELEGA:
    'Modifica lo stato della delega ed eventualmente le date d\'inizio e fine validità.',
  MGU_DELEGA_EDIT_MODAL_TEXT_DELEGA_ATTENTION: 'Attenzione: ',
  MGU_DELEGA_EDIT_MODAL_TEXT_DELEGA_ATTENTION_TEXT:
    'modificando lo stato della delega vengono automaticamente modificati gli stati relativi a tutti i procedimenti associati alla delega.',
  MGU_DELEGA_EDIT_MODAL_TITLE_PROCEDIMENTO: 'Procedimento',
  MGU_DELEGA_EDIT_MODAL_TEXT_PROCEDIMENTO:
    'Modifica lo stato del procedimento ed eventualmente le date d\'inizio e fine validità',
  MGU_DELEGA_EDIT_MODAL_ERROR_UPDATE_MESSAGE:
    'Non è stato possibile effettuare il salvataggio, riprova più tardi',
  MGU_DELEGA_EDIT_MODAL_SUCCESS_PROCEDIMENTO_UPDATE_TITLE:
    'Stato procedimento delega modificato',
  MGU_DELEGA_EDIT_MODAL_SUCCESS_PROCEDIMENTO_UPDATE_MESSAGE:
    'Lo stato del procedimento è stato correttamente modificato',
  MGU_DELEGA_EDIT_MODAL_SUCCESS_DELEGA_UPDATE_TITLE: 'Stato delega modificato',
  MGU_DELEGA_EDIT_MODAL_SUCCESS_DELEGA_UPDATE_MESSAGE:
    'Lo stato della delega è stato correttamente modificato',
  MGU_DELEGA_EDIT_FOOTER_CLOSE: 'OK. Chiudi',
  MGU_DELEGA_EDIT_TABLE_PROCEDIMENTI_TART_VALIDITA: 'Inizio validità',
  MGU_DELEGA_EDIT_TABLE_PROCEDIMENTI_ND_VALIDITA: 'Fine validità',
  MGU_DELEGA_EDIT_TABLE_PROCEDIMENTI_TATO: 'Stato',

  MGU_DELEGA_DETAIL_ACTIONS_BUTTONS_EDIT_STATE: 'Modifica stato',
  MGU_DELEGA_DETAIL_ID: 'ID delega',
  MGU_DELEGA_DETAIL_FUNCTION: 'Funzione',
  MGU_DELEGA_DETAIL_KIND: 'Tipo richiedente',
  MGU_DELEGA_DETAIL_BEGIN_VALIDITY: 'Data inizio validità',
  MGU_DELEGA_DETAIL_END_VALIDITY: 'Data fine validità',
  MGU_DELEGA_DETAIL_STATE: 'Stato',
  MGU_DELEGA_DETAIL_TABS_DATA: 'Dati delega',
  MGU_DELEGA_DETAIL_TABS_DOCUMENTS: 'Allegati',
  MGU_DELEGA_DETAIL_TABS_PROCEDIMENTI: 'Procedimenti',
  MGU_DELEGA_DETAIL_TABS_DATA_INSERITORE: 'Dati inseritore',
  MGU_DELEGA_DETAIL_TABS_DATA_APPLICANT: 'Dati richiedente',
  MGU_DELEGA_DETAIL_TABS_DATA_DELEGATE: 'Dati delegato',
  // begin case giuridico
  MGU_DELEGA_DETAIL_TABS_DATA_COMPANY_NAME: 'Denominazione azienda',
  MGU_DELEGA_DETAIL_TABS_DATA_CAPITALE_SOCIALE: 'Capitale sociale',
  MGU_DELEGA_DETAIL_TABS_DATA_CODE: 'Codice identificativo',
  MGU_DELEGA_DETAIL_TABS_DATA_PIVA: 'Partita Iva',
  MGU_DELEGA_DETAIL_TABS_DATA_LEGAL_ADDRESS: 'Indirizzo',
  MGU_DELEGA_DETAIL_TABS_DATA_LEGAL_CAP: 'CAP',
  MGU_DELEGA_DETAIL_TABS_DATA_LEGAL_NATION: 'Nazione',
  MGU_DELEGA_DETAIL_TABS_DATA_LEGAL_PROVINCE: 'Provincia',
  MGU_DELEGA_DETAIL_TABS_DATA_LEGAL_CITY: 'Comune',
  MGU_DELEGA_DETAIL_TABS_DATA_LEGAL_PEC: 'PEC',
  MGU_DELEGA_DETAIL_TABS_DATA_LEGAL_REPRESENTED_FISCAL_CODE:
    'CF rappresentante',
  MGU_DELEGA_DETAIL_TABS_DATA_LEGAL_REPRESENTED_NAME: 'Nome rappresentante',
  MGU_DELEGA_DETAIL_TABS_DATA_LEGAL_REPRESENTED_SURNNAME:
    'Cognome rappresentante',
  // end case giuridico
  MGU_DELEGA_DETAIL_TABS_DATA_FISCAL_CODE: 'Codice Fiscale',
  MGU_DELEGA_DETAIL_TABS_DATA_NAME: 'Nome',
  MGU_DELEGA_DETAIL_TABS_DATA_SURNAME: 'Cognome',
  MGU_DELEGA_DETAIL_TABS_DATA_DATE_OF_BIRTH: 'Data di nascita',
  MGU_DELEGA_DETAIL_TABS_DATA_SEX: 'Sesso',
  MGU_DELEGA_DETAIL_TABS_DATA_FUNCTION: 'Funzione',
  MGU_DELEGA_DETAIL_TABS_DATA_NATION: 'Nazione di nascita',
  MGU_DELEGA_DETAIL_TABS_DATA_PROVINCE: 'Provincia di nascita',
  MGU_DELEGA_DETAIL_TABS_DATA_PROVINCE_EST: 'Città estera di nascita',
  MGU_DELEGA_DETAIL_TABS_DATA_CITY: 'Comune di nascita',
  MGU_DELEGA_DETAIL_TABS_DATA_RESIDENCE: 'Residenza',
  MGU_DELEGA_DETAIL_TABS_DATA_ADDRESS: 'Indirizzo di residenza',
  MGU_DELEGA_DETAIL_TABS_DATA_CAP: 'CAP di residenza',
  MGU_DELEGA_DETAIL_TABS_DATA_NATION_RESIDENCE: 'Nazione di residenza',
  MGU_DELEGA_DETAIL_TABS_DATA_PROVINCE_RESIDENCE: 'Provincia di residenza',
  MGU_DELEGA_DETAIL_TABS_DATA_CITY_RESIDENCE: 'Comune di residenza',
  MGU_DELEGA_DETAIL_TABS_DATA_CITY_RESIDENCE_EST: 'Città estera di residenza',
  MGU_DELEGA_DETAIL_TABS_DATA_PHONE: 'Telefono',
  MGU_DELEGA_DETAIL_TABS_DATA_CELLULARE: 'Cellulare',
  MGU_DELEGA_DETAIL_TABS_DATA_EMAIL: 'Email',
  MGU_DELEGA_DETAIL_TABS_DATA_PEC: 'Pec',
  MGU_DELEGA_DETAIL_TABS_DATA_I_CC: 'Iscrizione camera di commercio',
  MGU_DELEGA_DETAIL_TABS_DATA_REA: 'Numero REA',
  MGU_DELEGA_DETAIL_TABS_DATA_SCADENZA_SOCIETA: 'Data scadenza società',
  MGU_DELEGA_DETAIL_TABS_DATA_DURATA_SOCIETA: 'Durata società',
  MGU_DELEGA_DETAIL_TABS_DOCUMENTS_LIST_COLUMNS_NAME: 'Nome allegato',
  MGU_DELEGA_DETAIL_TABS_DOCUMENTS_LIST_COLUMNS_APPLICANT_NAME:
    'Nome richiedente',
  MGU_DELEGA_DETAIL_TABS_DOCUMENTS_LIST_COLUMNS_DELEGATE_NAME: 'Nome delegato',
  MGU_DELEGA_DETAIL_TABS_DOCUMENTS_LIST_COLUMNS_APPLICANT_DATA_UPLOAD:
    'Date caricamento',
  MGU_DELEGA_DETAIL_TABS_DOCUMENTS_TOASTR_DOWNLOAD_ERROR_MESSAGE:
    'Non è stato possibile scaricare l\'allegato selezionato, riprova più tardi',
  MGU_DELEGA_DETAIL_TABS_PROCEDIMENTI_LIST_COLUMNS_NAME: 'Nome procedimento',
  MGU_DELEGA_DETAIL_TABS_PROCEDIMENTI_LIST_COLUMNS_BEGIN_VALIDITY:
    'Data inizio validità',
  MGU_DELEGA_DETAIL_TABS_PROCEDIMENTI_LIST_COLUMNS_END_VALIDITY:
    'Data fine validità',
  MGU_DELEGA_DETAIL_TABS_PROCEDIMENTI_LIST_COLUMNS_STATE: 'Stato',

  MGU_DELEGA_DETAIL_TABS_PROCEDIMENTI_LIST_COLUMNS_TIPO_DELEGA:
    'Tipologia delega',
  MGU_DELEGA_TIPO_DELEGA_FIRMA: 'Firma',
  MGU_DELEGA_TIPO_DELEGA_OPERATIVA: 'Operativa',

  MGU_DELEGA_DETAIL_TOASTR_FETCH_ERROR_TITLE: 'Errore',
  MGU_DELEGA_DETAIL_TOASTR_FETCH_ERROR_TEXT:
    'Non è stato possibile recuperare i dati relativi alla delega, riprova più tardi',

  MGU_DELEGA_DETAIL_TOASTR_FETCH_DOCUMENTS_ERROR_TITLE: 'Errore',
  MGU_DELEGA_DETAIL_TOASTR_FETCH_DOCUMENTS_ERROR_TEXT:
    'Non è stato possibile recuperare gli allegati relativi alla delega, riprova più tardi',
  MGU_DELEGA_DETAIL_TOASTR_FETCH_PROCESS_ERROR_TITLE: 'Errore',
  MGU_DELEGA_DETAIL_TOASTR_FETCH_PROCESS_ERROR_TEXT:
    'Non è stato possibile recuperare i procedimenti relativi alla delega, riprova più tardi',

  MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_GENERAL_DATA_EMAIL_ERROR:
    'Email non valida',

  MGU_ORGANITATIONS_LIST_BUTTON_EDIT_TITLE: 'Modifica',
  MGU_ORGANITATIONS_LIST_BUTTON_DELETE_TITLE: 'Elimina',
  MGU_ORGANITATIONS_LIST_BUTTON_MOVE_ORGANIZATION_TITLE:
    'Sposta organizzazione',

  MGU_UTENTI_ORGANITATIONS_LIST_BUTTON_EDIT_TITLE: 'Modifica dati utente',
  MGU_UTENTI_ORGANITATIONS_LIST_BUTTON_REMOVE_TITLE: 'Rimuovi associazione',
  MGU_UTENTI_ROLE_LIST_BUTTON_EDIT_TITLE: 'Modifica ruolo',
  MGU_UTENTI_LIST_ICON_BLOCKED_TITLE: 'Utente bloccato',
  MGU_UTENTI_LIST_ICON_STATE_undefined: '',
  MGU_UTENTI_LIST_ICON_STATE_DA_VERIFICARE: 'Da verificare',
  MGU_UTENTI_LIST_ICON_STATE_APPROVATO: 'Approvato',
  MGU_UTENTI_LIST_ICON_STATE_NEGATO: 'Negato',
  MGU_DELEGHE_BUTTON_DOWNLOAD_DOCUMENT: 'Scarica documento',
  MGU_DELEGHE_BUTTON_EDIT_VALIDITY: 'Modifica validità',
  MGU_DELEGHE_DETAIL_PROCEDIMENTO_BUTTON_EDIT_STATE: 'Modifica stato',
  MGU_ORGANITATIONS_DETAIL_TAB_USER_BUTTON_REMOVE_USER: 'Rimuovi utente',
  MGU_ORGANITATIONS_DETAIL_TAB_USER_BUTTON_EDIT_INC: 'Modifica incardinamento',

  MGU_UTENTI_INCARICHI_LIST_BUTTON_EDIT_TITLE: 'Modifica dati incarico',
  MGU_USER_DETAIL_USERNAME_ERROR_NO_MAIL_TITLE:
    'Nessuna mail per il dipendente',
  MGU_USER_DETAIL_USERNAME_ERROR_NO_MAIL_TEXT:
    'Per procedere con l\'operazione è obbligatorio che il dipendente abbia una mail associata',

  FUNZIONI_: '---',
  FUNZIONI_ISTRUTTORE: 'Istruttore',
  FUNZIONI_RESPONSABILE_CONCESSIONI: 'Responsabile Concessioni',
  FUNZIONI_SUPERVISORE_CONTI_FINANZIAMENTI: 'Supervisore Conti Finanziamenti',

  FUNZIONI_RESPONSABILE_PROCEDIMENTO: 'Responsabile procedimento',
  FUNZIONI_IMPIEGATO: 'Impiegato',
  FUNZIONI_RESPONSABILE_PROVVEDIMENTO: 'Responsabile Provvedimento',
  FUNZIONI_RPCT:
    'Responsabile per la Prevenzione della Corruzione e la Trasparenza',

  MGU_PROCEDIMENTO_LIST_COLUMNS_STATO_PUBBLICATO: 'Pubblicato',
  MGU_PROCEDIMENTO_LIST_COLUMNS_STATO_DA_PUBBLICARE: 'Da pubblicare',
  MGU_PROCEDIMENTO_LIST_COLUMNS_STATO_BOZZA: 'Bozza',
  MGU_PROCEDIMENTO_LIST_COLUMNS_STATO_: '--',

  MGU_PROCEDIMENTO_LIST_FILTER_FORM_NOME_LABEL: 'Nome procedimento',
  MGU_PROCEDIMENTO_LIST_FILTER_FORM_NOME_PLACEHOLDER: 'Cerca per nome',
  MGU_PROCEDIMENTO_LIST_FILTER_FORM_CODE_LABEL: 'Codice procedimento',
  MGU_PROCEDIMENTO_LIST_FILTER_FORM_CODE_PLACEHOLDER: 'Cerca per codice',
  MGU_PROCEDIMENTO_LIST_FILTER_FORM_STATO_LABEL: 'Stato di pubblicazione',

  MGU_PROCEDIMENTO_DEFAULT_USER: 'Assegnazione utente di default',
  MGU_PROCEDIMENTO_LIST_BUTTON_EDIT_INC: 'Modifica dati utente',
  MGU_PROCEDIMENTO_LIST_BUTTON_REMOVE_USER: 'Rimuovi procedimento',

  SUPERVISORE_CONTI_FINANZIAMENTI: 'Supervisore Conti Finanziamenti',
  ISTRUTTORE: 'Istruttore',
  RESPONSABILE_CONCESSIONI: 'Responsabile Concessioni',
  IMPIEGATO: 'Impiegato',
  RESPONSABILE_PROCEDIMENTO: 'Responsabile procedimento',
  RESPONSABILE_PROVVEDIMENTO: 'Responsabile Provvedimento',
  RPCT: 'Responsabile per la Prevenzione della Corruzione e la Trasparenza',

  MGU_UTENTE_LIST_POPOVER_BLOCK_USER: 'Blocca utente',
  MGU_UTENTE_LIST_POPOVER_UNLOCK_USER: 'Sblocca utente',
  MGU_UTENTE_LIST_POPOVER_RESET_PASSWORD: 'Reset password',

  MGU_UTENTE_LIST_MODAL_BLOCK_USER_TITLE: 'Blocca utente',
  MGU_UTENTE_LIST_MODAL_BLOCK_USER_TEXT:
    'Confermi di voler bloccare l’account dell’utente?',
  MGU_UTENTE_LIST_MODAL_UNLOCK_USER_TITLE: 'Sblocca utente',
  MGU_UTENTE_LIST_MODAL_UNLOCK_USER_TEXT:
    'Confermi di voler sbloccare l’account dell’utente?',
  MGU_UTENTE_LIST_MODAL_RESET_PASSWORD_TITLE: 'Reset password',
  MGU_UTENTE_LIST_MODAL_RESET_PASSWORD_TEXT_1:
    'Confermi di voler effettuare il reset della password associata all’account dell’utente?',
  MGU_UTENTE_LIST_MODAL_RESET_PASSWORD_TEXT_2:
    'Confermando l’azione, l’utente riceverà una email contenente un link per effettuare il reset della propria password.',
  MGU_UTENTE_LIST_TOASTR_BLOCK_ERROR_TEXT:
    'Non è stato possibile bloccare l\'utente, riprova più tardi',
  MGU_UTENTE_LIST_TOASTR_BLOCK_SUCCESS_TITLE: 'Operazione confermata',
  MGU_UTENTE_LIST_TOASTR_BLOCK_SUCCESS_TEXT:
    'L\'utente è stato bloccato con successo',
  MGU_UTENTE_LIST_TOASTR_UNBLOCK_SUCCESS_TEXT:
    'L\'utente è stato sbloccato con successo',
  MGU_UTENTE_LIST_TOASTR_VALIDA_SUCCESS_TITLE: 'Operazione confermata',
  MGU_UTENTE_LIST_TOASTR_VALIDA_APPROVATO_SUCCESS_TEXT:
    ' stato approvato l\'accesso SUA a questo utente',
  MGU_UTENTE_LIST_TOASTR_VALIDA_NEGATO_SUCCESS_TEXT:
    'Non è stato approvato l\'accesso SUA a questo utente',

  MGU_UTENTE_LIST_TOASTR_RESET_PASW_ERROR_TEXT:
    'Non è stato possibile richiedere il reset della password, riprova più tardi',
  MGU_UTENTE_LIST_TOASTR_RESET_PASW_SUCCESS_TITLE: 'Operazione confermata',
  MGU_UTENTE_LIST_TOASTR_RESET_PASW_SUCCESS_TEXT:
    'É stato effettuato il reset password con successo',
  SCRIVANIA_FORM_FILTER_DELEGHE_P_IVA_RICHIEDENTE_LABEL:
    'Partita Iva Richiedente',
  SCRIVANIA_FORM_FILTER_DELEGHE_P_IVA_RICHIEDENTEPLACEHOLDER:
    'Cerca per P. Iva Richiedente',

  MAP_CATEGORIE_ORGANIZZAZIONE_ADSP: 'ADSP',
  MAP_CATEGORIE_ORGANIZZAZIONE_AGENZIA_DELLE_DOGANE: 'Agenzia delle Dogane',
  MAP_CATEGORIE_ORGANIZZAZIONE_GUARDIA_DI_FINANZA: 'Guardia di Finanza',
  MAP_CATEGORIE_ORGANIZZAZIONE_COMMISSARIATO_PS_PORTO:
    'Commissariato P.S. porto',
  MAP_CATEGORIE_ORGANIZZAZIONE_CAPITANERIA_DI_PORTO: 'Capitaneria di Porto',
  MAP_CATEGORIE_ORGANIZZAZIONE_PREFETTURA: 'Prefettura',

  MGU_USER_DETAIL_STATO_SUA: 'Accesso SUA',
  STATO_SUA_DA_VERIFICARE: 'Da verificare',
  STATO_SUA_APPROVATO: 'Approvato',
  STATO_SUA_NEGATO: 'Negato',

  MGU_UTENTE_LIST_MODAL_DA_VALIDARE_TITLE: 'Convalida l\'accesso SUA',
  MGU_UTENTE_LIST_MODAL_DA_VALIDARE_TEXT_1:
    'Puoi approvare o negare l\'accesso tramite SUA a questo utente.',
  MGU_UTENTE_LIST_MODAL_DA_VALIDARE_TEXT_2:
    'Finchè non sarà approvato non potrà accedere tramite SUA, una volta effettuata una scelta non si potrà cambiare.',
  UTILS_CHANGE_STATO_SUA_NEGATO: 'Nega',
  UTILS_CHANGE_STATO_SUA_APPROVATO: 'Approva',
  MGU_USER_LIST_FILTER_FORM_AUTENTICATO_LABEL: 'Stato Accesso SUA',
  MGU_USER_LIST_FILTER_FORM_AUTENTICATOS_PLACEHOLDER: 'Seleziona stato',
  STATO_SUA_LEGENDA_APPROVATO: 'Accesso SUA Approvato',
  STATO_SUA_LEGENDA_DA_VERIFICARE: 'Accesso SUA da verificare',
  STATO_SUA_LEGENDA_NEGATO: 'Accesso SUA Negato',
  MGU_DELEGA_DETAIL_TABS_SEDE_CC: 'Sede camera di commercio',
  MGU_DELEGA_DETAIL_TABS_DATA_DATE_I_CC: 'Data iscrizione camera di commercio',
  MGU_DELEGA_DETAIL_TABS_DATA_TIPO_SOCIETA_CC: 'Tipo società',
  MGU_DELEGA_DETAIL_TABS_DATA_TIPO_AZIENDA_CC: 'Tipo azienda',
  MGU_DELEGA_DETAIL_TABS_DATA_NUMERO_ISCRIZIONE_AA:
    'Numero iscrizione albo autotrasporti',
  MGU_DELEGA_DETAIL_TABS_DATA_NUMERO_ISCRIZIONE_REN: 'Numero iscrizione REN',
  MGU_DELEGA_DETAIL_TABS_DATA_PROVINCE_REA: 'Sede REA',
  MGU_DELEGA_DETAIL_TABS_DATA_DATE_REA: 'Data iscrizione REA',
  MGU_DELEGA_DETAIL_TABS_DATA_SEDE_LEGALE_ADDRESS: 'Indirizzo sede legale',
  MGU_DELEGA_DETAIL_TABS_DATA_SEDE_LEGALE_CAP: 'CAP sede legale',
  MGU_DELEGA_DETAIL_TABS_DATA_SEDE_LEGALE_NATION: 'Nazione sede legale',
  MGU_DELEGA_DETAIL_TABS_DATA_SEDE_LEGALE_PROVINCE: 'Provincia sede legale',
  MGU_DELEGA_DETAIL_TABS_DATA_SEDE_LEGALE_CITY: 'Comune sede legale',
  MGU_DELEGA_DETAIL_TABS_DATA_SEDE_LEGALE_CITY_EST: 'Città estera sede legale',
  MGU_DELEGA_DETAIL_TABS_DATA_SEDE_FISCALE_ADDRESS:
    'Indirizzo domicilio fiscale',
  MGU_DELEGA_DETAIL_TABS_DATA_SEDE_FISCALE_CAP: 'CAP domicilio fiscale',
  MGU_DELEGA_DETAIL_TABS_DATA_SEDE_FISCALE_NATION: 'Nazione domicilio fiscale',
  MGU_DELEGA_DETAIL_TABS_DATA_SEDE_FISCALE_PROVINCE:
    'Provincia domicilio fiscale',
  MGU_DELEGA_DETAIL_TABS_DATA_SEDE_FISCALE_CITY: 'Comune domicilio fiscale',
  MGU_DELEGA_DETAIL_TABS_DATA_SEDE_FISCALE_CITY_EST:
    'Città estera domicilio fiscale',
  MGU_DELEGA_DETAIL_TABS_DATA_LEGAL_REPRESENTED_FUNCTION:
    'Funzione rappresentante',
  MGU_USER_DETAIL_CELLULARE: 'Cellulare',
  MGU_DELEGA_DETAIL_TABS_DATA_CELL: 'Cellulare',
  MGU_DELEGA_DETAIL_TABS_DATA_FISSO: 'Telefono',
  EDIT_STATUS_SUA_ACTION_USER: 'Modifica stato SUA',
  MGU_EDIT_STATO_DIALOG_STATO_LABEL: 'Stato SUA',
  MGU_EDIT_STATO_DIALOG_STATO_LABEL_PLACEHOLDER: 'Seleziona uno stato',
  MGU_EDIT_STATO_DIALOG_STATO_BUTTON: 'Modifica',
  MGU_EDIT_STATO_DIALOG_STATO_TITLE: 'Modifica stato SUA',
  TIPO_null: '---',
  TIPO_SOCIETA_PER_AZIONI: 'Società per azioni',
  TIPO_SOCIETA_RESPONSABILITA_LIMITATA: 'Società a responsabilità limitata',
  TIPO_AZIENDA_AUTOTRASPORTI: 'Azienda autotrasporti',
  TIPO_AZIENDA_PORTUALE: 'Azienda portuale',

  TIPO_FUNZIONE_MANUTENZIONE: 'Manutenzione',
  TIPO_FUNZIONE_DIPENDENTE: 'Dipendente',
  TIPO_FUNZIONE_STAGISTA: 'Stagista',

  TIPO_INCARICO_ASPP: 'ASPP',
  TIPO_INCARICO_DPSO: 'DPSO',
  TIPO_INCARICO_RTD: 'RTD',
  TIPO_INCARICO_DPO: 'DPO',
  TIPO_INCARICO_PSO: 'PSO',
  TIPO_INCARICO_RPCT: 'RPCT',
  TIPO_INCARICO_RSPP: 'RSPP',

  PORTO_RIFERIMENTO_TARANTO: 'Taranto',
  PORTO_RIFERIMENTO_CATANIA: 'Catania',
  PORTO_RIFERIMENTO_GENOVA: 'Genova',
  PORTO_RIFERIMENTO_SAVONA: 'Savona',
  USER_PROFILE_PHONE_LABEL: 'Telefono',
  USER_PROFILE_PHONE_PLACEHOLDER: 'Inserisci n. telefono fisso',
  USER_PROFILE_PHONE_ERROR: 'Numero telefono fisso non valido',
  USER_PROFILE_MOBILE_ERROR: 'Numero cellulare non valido',
  USER_PROFILE_MOBILE_PHONE_LABEL: 'Cellulare',
  USER_PROFILE_MOBILE_PHONE_PLACEHOLDER: 'Inserisci n. cellulare',
  USER_PROFILE_FORM_INPUT_PEC_LABEL: 'Pec',
  USER_PROFILE_FORM_INPUT_PEC_PLACEHOLDER: 'Inserisci pec',
  PROFILE_FORM_INPUT_NAME_LABEL: 'Nome',
  PROFILE_FORM_INPUT_NAME_PLACEHOLDER: 'Inserisci nome',

  PROFILE_FORM_INPUT_LASTNAME_LABEL: 'Cognome',
  PROFILE_FORM_INPUT_LASTNAME_PLACEHOLDER: 'Inserisci cognome',

  PROFILE_FORM_INPUT_FISCAL_CODE_LABEL: 'Codice fiscale',
  PROFILE_FORM_INPUT_FISCAL_CODE_PLACEHOLDER: 'Inserisci codice fiscale',

  PROFILE_FORM_INPUT_CITY_LABEL: 'Comune',
  PROFILE_FORM_INPUT_CITY_PLACEHOLDER: 'Inserisci comune di residenza',

  PROFILE_FORM_INPUT_ADDRESS_LABEL: 'Indirizzo',
  PROFILE_FORM_INPUT_ADDRESS_PLACEHOLDER: 'Inserisci indirizzo di residenza',

  PROFILE_FORM_INPUT_POSTAL_CODE_LABEL: 'CAP',
  PROFILE_FORM_INPUT_POSTAL_CODE_PLACEHOLDER: 'Inserisci CAP di residenza',

  PROFILE_FORM_EMAIL_TITLE: 'Email',

  PROFILE_FORM_INPUT_EMAIL_LABEL: 'Email',
  PROFILE_FORM_INPUT_EMAIL_PLACEHOLDER: 'Inserisci indirizzo posta elettronica',

  PROFILE_FORM_INPUT_WORK_EMAIL_LABEL: 'Email ufficio',
  PROFILE_FORM_INPUT_WORK_EMAIL_PLACEHOLDER:
    'Inserisci indirizzo posta ufficio',

  PROFILE_FORM_INPUT_BIRTH_DATE: 'Data di nascita',
  PROFILE_FORM_INPUT_BIRTH_STATE: 'Nazione di nascita',
  PROFILE_FORM_INPUT_SEX: 'Sesso',
  PROFILE_MALE_SEX_OPTION: 'M',
  PROFILE_FEMALE_SEX_OPTION: 'F',
  PROFILE_FORM_INPUT_BIRTH_PROVINCE: 'Provincia di nascita',
  PROFILE_FORM_INPUT_BIRTH_CITY: 'Comune di nascita',

  PROFILE_FORM_INPUT_RESIDENTIAL_STATE: 'Nazione di residenza',
  PROFILE_FORM_INPUT_RESIDENTIAL_PROVINCE: 'Provincia di residenza',
  PROFILE_FORM_INPUT_RESIDENTIAL_CITY: 'Comune di residenza',

  PROFILE_ACTIONS_FOOTER_SAVE: 'Salva',
  MGU_UTENTI_LIST_BUTTON_NEW: 'Inserisci nuovo utente',
  MGU_USER_SAVE_INSERISCI_BUTTON: 'Inserisci',
  MGU_USER_NEW_USER_TOASTR_SUCCESS_TITLE: 'Operazione riuscita',
  MGU_USER_NEW_USER_TOASTR_SUCCESS_TEXT: 'Utente inserito correttamente',
  MGU_USER_DETAIL_ALL_INFO: 'Tutte le info',
  MGU_USER_DETAIL_UTENTE_INFO: 'Dati dell\'utente',
  VERTICALE_FRONTOFFICE: 'Cittadino',
  VERTICALE_AMMINISTRAZIONE: 'Amministrazione',
  VERTICALE_SUA: 'SUA',
  VERTICALE_RUA: 'RUA',
  VERTICALE_PATRIMONIO: 'Patrimonio',
};
