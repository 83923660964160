import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { I18nService } from '@eng-ds/translate';
import { AutoUnsubscribe } from 'src/app/core/components';
import {
  AutocompleteInput,
  BaseInput,
  DateInput,
  Form,
  ValidationStatus,
} from 'src/app/shared/form';

import { IncardinamentoEsteso } from '../../models';
import { FunzioniIncardinamentoService } from '../../utils/funzioni-incardinamento.service';

@Component({
  selector: ' adsp-mgu-modale-incardinamento',
  templateUrl: './modale-incardinamento.component.html',
  styleUrls: ['./modale-incardinamento.component.css'],
})
export class ModaleIncardinamentoComponent
  extends AutoUnsubscribe
  implements OnInit
{
  @Output() incardinamentoCompleted: EventEmitter<IncardinamentoEsteso[]> =
    new EventEmitter();
  @Output() incardinamentoCancelled: EventEmitter<boolean> = new EventEmitter();
  formGroupIncardinamento: FormGroup;
  private _gestionProfile: boolean = false;
  private emailValidationStatus = [
    ValidationStatus.ERROR.REQUIRED,
    ValidationStatus.WARNING((control) => control.hasError('pattern'), {
      text: 'MGU_INCARDINAMENTO_EMAIL_FORMAT_ERROR',
    }),
  ];
  private emailRegex = '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$';

  constructor(
    private funzioniIncardinamentoService: FunzioniIncardinamentoService,
    private i18n: I18nService
  ) {
    super();
  }

  private _elementSelected: IncardinamentoEsteso[];

  get elementSelected(): IncardinamentoEsteso[] {
    return this._elementSelected;
  }

  @Input() set elementSelected(value: IncardinamentoEsteso[]) {
    this._elementSelected = value;
  }

  @Input() set info(value: boolean) {
    this._gestionProfile = value;
  }

  private _open = false;

  get open(): boolean {
    return this._open;
  }

  @Input() set open(value: boolean) {
    this._open = value;
    if (this._open) {
      this.initForm(this._elementSelected);
    }
  }

  ngOnInit(): void {}

  annullaIncardinamentoOrganizzazione(): void {
    this._open = false;
    this.incardinamentoCancelled.emit(true);
  }

  salvaIncardinamentoOrganizzazione(): void {
    const value = this.formGroupIncardinamento.value;
    this.elementSelected.map((iEsteso: IncardinamentoEsteso) => {
      iEsteso.incardinamento = !this._gestionProfile
        ? this.incardinamentoTot(iEsteso.incardinamento, value[iEsteso.uuid])
        : this.incardinamentoProfilo(
            iEsteso.incardinamento,
            value[iEsteso.uuid]
          );
      iEsteso.incardinamento.uuid = iEsteso.uuid;
      return iEsteso;
    });

    this.open = false;

    // this gains time form the dialog to hide properly
    setTimeout(() => {
      this.incardinamentoCompleted.emit(this.elementSelected);
    }, 100);
  }

  incardinamentoProfilo(organizzazione, formValue) {
    return {
      dataFineValidita: organizzazione?.dataFineValidita,
      dataInizioValidita: organizzazione?.dataInizioValidita,
      funzioneOrganizzazione: organizzazione?.funzioneOrganizzazione,
      email: formValue?.email,
      pec: formValue?.pec,
      telefono: formValue?.telefono,
      uuid: organizzazione?.uuid,
    };
  }

  incardinamentoTot(organizzazione, formValue) {
    let incardinamentoTot: any = {
      dataFineValidita: formValue?.dataFineValidita
        ? formatDate(formValue?.dataFineValidita, 'yyyy-MM-ddThh:mm', 'IT-it')
        : null,
      dataInizioValidita: formatDate(
        formValue?.dataInizioValidita,
        'yyyy-MM-ddThh:mm',
        'IT-it'
      ),
      email: formValue?.email,
      pec: formValue?.pec,
      telefono: formValue?.telefono,
      uuid: this._elementSelected[0].uuid,
      funzioneOrganizzazione: formValue?.funzioneOrganizzazione,
    };
    return incardinamentoTot;
  }

  checkFormExists(uuid: string | undefined) {
    return this.formGroupIncardinamento?.get(uuid);
  }

  private initForm(selected: IncardinamentoEsteso[]): void {
    this.formGroupIncardinamento = new FormGroup({});
    selected.forEach((iEsteso: IncardinamentoEsteso) => {
      this.formGroupIncardinamento.addControl(
        iEsteso.uuid,
        this._getForm(iEsteso)
      );
    });
    this.formGroupIncardinamento.markAllAsTouched();
  }

  private _getForm(iEsteso: IncardinamentoEsteso) {
    return new Form({
      header: {
        show: false,
      },
      controls: this._getIncardinamentoFields(iEsteso),
    });
  }

  private _getIncardinamentoFields(iEsteso: IncardinamentoEsteso): {
    [key: string]: BaseInput<any>;
  } {
    return {
      funzioneOrganizzazione: new AutocompleteInput({
        formGroupClass: ['mb-2'],
        size: '12|12|12|12|12',
        placeholder: 'MGU_INCARDINAMENTO_MODAL_FORM_FILTER_FUNZIONE_PLACEHOLER',
        options: this.funzioniIncardinamentoService.getFunzioneIncardinamento(),
        value: iEsteso.incardinamento.funzioneOrganizzazione
          ? iEsteso.incardinamento.funzioneOrganizzazione
          : null,
        label: 'MGU_INCARDINAMENTO_MODAL_FORM_FILTER_FUNZIONE',
        required: true,
        validationStatus: [ValidationStatus.ERROR.REQUIRED],
      }),
      dataInizioValidita: new DateInput({
        value: iEsteso?.incardinamento?.dataInizioValidita
          ? formatDate(
              iEsteso?.incardinamento?.dataInizioValidita,
              'yyyy-MM-dd',
              'IT-it'
            )
          : null,
        label: 'MGU_INCARDINAMENTO_MODAL_FORM_START_DATE',
        size: '12|12|12|6|6',
        formGroupClass: ['mb-1', 'p-0'],
        required: true,
        validationStatus: [
          ValidationStatus.ERROR.REQUIRED,
          ValidationStatus.WARNING((control) => control.hasError('maxlength'), {
            text: this.i18n.translate('VALIDATIONS_PATTERN_DATE'),
          }),
        ],
      }),
      dataFineValidita: new DateInput({
        value: iEsteso?.incardinamento?.dataFineValidita
          ? formatDate(
              iEsteso?.incardinamento?.dataFineValidita,
              'yyyy-MM-dd',
              'IT-it'
            )
          : null,
        formGroupClass: ['mb-1', 'p-0'],
        label: 'MGU_INCARDINAMENTO_MODAL_FORM_END_DATE',
        size: '12|12|12|6|6',
        required: false,
        validationStatus: [
          ValidationStatus.WARNING((control) => control.hasError('maxlength'), {
            text: this.i18n.translate('VALIDATIONS_PATTERN_DATE'),
          }),
        ],
      }),
    };
  }
}
