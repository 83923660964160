import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { TranslateModule } from '@eng-ds/translate';
import { FormModule } from '../form';
import { TableComponent } from './components/table/table.component';
import { TableTbodyComponent } from './components/tbody/table-tbody.component';
import { TableTheadComponent } from './components/thead/table-thead.component';
import { DeepObjectPipe } from './pipes/deep-object.pipe';

@NgModule({
  imports: [CommonModule, TranslateModule.forChild(), FormModule],
  declarations: [
    TableComponent,
    TableTheadComponent,
    TableTbodyComponent,
    DeepObjectPipe,
  ],
  exports: [TableComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class TableModule {}
