<agid-modal [open]="open" position="center" class="dialog" (closed)="onClose()">
  <agid-modal-header>
    <span class="title">{{
      "MGU_USER_DETAIL_USERNAME_DIALOG_TITLE" | translate
    }}</span>
  </agid-modal-header>

  <agid-modal-body>
    <div *ngIf="errorMessage" class="alert alert-danger" role="alert">
      {{ errorMessage }}
    </div>

    <app-form *ngIf="form" [form]="form"></app-form>
  </agid-modal-body>

  <agid-modal-footer>
    <agid-button
      (click)="save()"
      [disabled]="!this.form.get('username').value"
      >{{ "UTILS_CONFIRM" | translate }}</agid-button
    >
  </agid-modal-footer>
</agid-modal>
