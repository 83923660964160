import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { I18nService } from '@eng-ds/translate';
import { AutoUnsubscribe } from 'src/app/core/components';
import {
  AutocompleteInput,
  BaseInput,
  DateInput,
  Form,
  TextInput,
  ValidationStatus,
} from 'src/app/shared/form';
import { OrganizzazioniService } from '../../../pages/organizzazioni/core/services';
import { SoggettoFisicoEnte } from '../../../pages/utenti/model';
import { of } from 'rxjs';

@Component({
  selector: 'adsp-mgu-modale-soggetto-fisico',
  templateUrl: './modale-soggetto-fisico.component.html',
  styleUrls: ['./modale-soggetto-fisico.component.css'],
  providers: [OrganizzazioniService],
})
export class ModaleSoggettoFisicoComponent extends AutoUnsubscribe {
  @Output() completed: EventEmitter<SoggettoFisicoEnte> = new EventEmitter();
  @Output() cancelled: EventEmitter<boolean> = new EventEmitter();
  formGroup: FormGroup;
  @Input() editRecapiti = false;
  @Input() editCategoria = false;
  @Input() uuidUtente: string;
  private _gestioneProfilo: boolean = false;
  private emailValidationStatus = [
    ValidationStatus.ERROR.REQUIRED,
    ValidationStatus.WARNING((control) => control.hasError('pattern'), {
      text: 'MGU_INCARDINAMENTO_EMAIL_FORMAT_ERROR',
    }),
  ];

  constructor(
    private organizzazioniService: OrganizzazioniService,
    private i18n: I18nService
  ) {
    super();
  }

  private _elementSelected: SoggettoFisicoEnte;

  get elementSelected(): SoggettoFisicoEnte {
    return this._elementSelected;
  }

  get isHr(): boolean {
    return this.elementSelected.hr;
  }

  @Input() set elementSelected(value: SoggettoFisicoEnte) {
    this._elementSelected = value;
  }

  @Input() set info(value: boolean) {
    this._gestioneProfilo = value;
  }

  private _open = false;

  get open(): boolean {
    return this._open;
  }

  @Input() set open(value: boolean) {
    this._open = value;
    if (this._open) {
      this.initForm(this._elementSelected);
    }
  }

  annulla(): void {
    this._open = false;
    this.cancelled.emit(true);
  }

  salva(): void {
    const formValue = this.formGroup.getRawValue()[this.uuidUtente];

    const soggettoFisicoReturn: SoggettoFisicoEnte = {
      dataFineValidita:
        formValue?.dataFineValidita || this.elementSelected?.dataFineValidita
          ? formatDate(
              formValue?.dataFineValidita ||
                this.elementSelected?.dataFineValidita,
              'yyyy-MM-ddThh:mm',
              'IT-it'
            )
          : null,
      dataInizioValidita:
        formValue?.dataInizioValidita ||
        this.elementSelected?.dataInizioValidita
          ? formatDate(
              formValue?.dataInizioValidita ||
                this.elementSelected?.dataInizioValidita,
              'yyyy-MM-ddThh:mm',
              'IT-it'
            )
          : null,
      email: formValue?.email,
      pec: formValue?.pec,
      telefono: formValue?.telefono,
      uuid: this.elementSelected.uuid,
      categoria: formValue?.categoria || this.elementSelected?.categoria,
      abilitatoRubrica: formValue?.abilitatoRubrica,
      abilitatoBackoffice: formValue?.abilitatoBackoffice,
      notificaControlloAccessi: formValue?.notificaControlloAccessi,
      funzioneCodice: formValue?.funzioneCodice,
      hr: formValue?.hr,
    };
    this.open = false;

    // this gains time form the dialog to hide properly
    setTimeout(() => {
      this.completed.emit(soggettoFisicoReturn);
    }, 100);
  }

  checkFormExists(uuid: string | undefined) {
    return this.formGroup?.get(uuid);
  }

  private initForm(selected: SoggettoFisicoEnte): void {
    this.formGroup = new FormGroup({});

    this.formGroup.addControl(this.uuidUtente, this._getForm(selected));
  }

  private _getForm(iEsteso: SoggettoFisicoEnte) {
    return new Form({
      header: {
        show: false,
      },
      controls: this._getIncardinamentoFields(iEsteso),
    });
  }

  private _getIncardinamentoFields(soggetto: SoggettoFisicoEnte): {
    [key: string]: BaseInput<any>;
  } {
    return {
      ...(this.editRecapiti && {
        email: new TextInput({
          formGroupClass: ['mb-2'],
          type: 'email',
          readonly: this.isHr,
          value: soggetto.email ? soggetto.email : null,
          label: 'MGU_INCARDINAMENTO_MODAL_FORM_EMAIL',
          placeholder: 'MGU_INCARDINAMENTO_MODAL_FORM_EMAIL_PLACEHOLER',
          size: '12|12|6|6|6',
          required: soggetto.email ? true : !soggetto.pec,
          pattern: '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$',
          validationStatus: this.emailValidationStatus,
        }),
        pec: new TextInput({
          formGroupClass: ['mb-2'],
          type: 'email',
          readonly: this.isHr,
          value: soggetto.pec ?? null,
          label: 'MGU_INCARDINAMENTO_MODAL_FORM_PEC',
          placeholder: 'MGU_INCARDINAMENTO_MODAL_FORM_PEC_PLACEHOLDER',
          size: '12|12|6|6|6',
          pattern: '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$',
          validationStatus: this.emailValidationStatus,
        }),
        telefono: new TextInput({
          formGroupClass: ['mb-2'],
          type: 'tel',
          readonly: this.isHr,
          value: soggetto.telefono ? soggetto.telefono : null,
          label: 'MGU_INCARDINAMENTO_MODAL_FORM_PHONE',
          placeholder: 'MGU_INCARDINAMENTO_MODAL_FORM_PHONE_PLACEHOLDER',
          size: '12|12|6|6|6',
          required: false,
          pattern: '^([+|0][0-9]{1,3})?[0-9]{3,14}$',
          validationStatus: [
            ValidationStatus.ERROR.REQUIRED,
            ValidationStatus.WARNING((control) => control.hasError('pattern'), {
              text: 'MGU_INCARDINAMENTO_MODAL_FORM_PHONE_FORMAT_ERROR',
            }),
          ],
        }),
      }),
      ...(!this._gestioneProfilo && {
        categoria: new AutocompleteInput({
          formGroupClass: ['mb-2'],
          readonly: this.isHr,
          value: soggetto?.categoria ?? null,
          options: this.organizzazioniService.getCategories(),
          label: 'MGU_INCARDINAMENTO_MODAL_FORM_FILTER_CATEGORIA',
          size: '12|12|6|6|6',
          placeholder:
            'MGU_INCARDINAMENTO_MODAL_FORM_FILTER_CATEGORIA_PLACEHOLER',
          required: true,
          validationStatus: [ValidationStatus.ERROR.REQUIRED],
        }),
        dataInizioValidita: new DateInput({
          value: soggetto?.dataInizioValidita
            ? formatDate(soggetto?.dataInizioValidita, 'yyyy-MM-dd', 'IT-it')
            : null,
          label: 'MGU_INCARDINAMENTO_MODAL_FORM_START_DATE',
          size: '12|12|12|6|6',
          readonly: this.isHr,
          formGroupClass: ['mb-2', 'p-0'],
          required: true,
          validationStatus: [
            ValidationStatus.ERROR.REQUIRED,
            ValidationStatus.WARNING(
              (control) => control.hasError('maxlength'),
              {
                text: this.i18n.translate('VALIDATIONS_PATTERN_DATE'),
              }
            ),
          ],
        }),
        dataFineValidita: new DateInput({
          value: soggetto?.dataFineValidita
            ? formatDate(soggetto?.dataFineValidita, 'yyyy-MM-dd', 'IT-it')
            : null,
          formGroupClass: ['mb-2', 'p-0'],
          readonly: this.isHr,
          label: 'MGU_INCARDINAMENTO_MODAL_FORM_END_DATE',
          size: '12|12|12|6|6',
          required: false,
          validationStatus: [
            ValidationStatus.WARNING(
              (control) => control.hasError('maxlength'),
              {
                text: this.i18n.translate('VALIDATIONS_PATTERN_DATE'),
              }
            ),
          ],
        }),
      }),
      ...(this.editRecapiti && {
        hr: new AutocompleteInput({
          formGroupClass: ['mb-2'],
          size: '12|12|6|6|6',
          placeholder: 'MGU_INCARDINAMENTO_MODAL_FORM_HR_PLACEHOLDER',
          options: of([
            {
              value: true,
              label: this.i18n.translate('UTILS_YES'),
            },
            {
              value: false,
              label: this.i18n.translate('UTILS_NOT'),
            },
          ]),
          value: soggetto?.hr ?? false,
          label: 'MGU_INCARDINAMENTO_MODAL_FORM_HR',
          required: true,
          readonly: true,
          validationStatus: [ValidationStatus.ERROR.REQUIRED],
        }),
        abilitatoRubrica: new AutocompleteInput({
          formGroupClass: ['mb-2'],
          size: '12|12|6|6|6',
          placeholder:
            'MGU_INCARDINAMENTO_MODAL_FORM_FILTER_VISIBILITA_RUBRICA_PLACEHOLER',
          options: of([
            {
              value: true,
              label: this.i18n.translate(
                'MGU_INCARDINAMENTO_MODAL_FORM_FILTER_VISIBILITA_RUBRICA_ABILITATO'
              ),
            },
            {
              value: false,
              label: this.i18n.translate(
                'MGU_INCARDINAMENTO_MODAL_FORM_FILTER_VISIBILITA_RUBRICA_DISABILITATO'
              ),
            },
          ]),
          value: soggetto?.abilitatoRubrica ?? false,
          label: 'MGU_INCARDINAMENTO_MODAL_FORM_FILTER_VISIBILITA_RUBRICA',
          required: true,
          validationStatus: [ValidationStatus.ERROR.REQUIRED],
        }),
        abilitatoBackoffice: new AutocompleteInput({
          formGroupClass: ['mb-2'],
          size: '12|12|6|6|6',
          placeholder:
            'MGU_INCARDINAMENTO_MODAL_FORM_ABILITATO_BACKOFFICE_PLACEHOLDER',
          options: of([
            {
              value: true,
              label: this.i18n.translate('UTILS_ABILITATO'),
            },
            {
              value: false,
              label: this.i18n.translate('UTILS_DISABILITATO'),
            },
          ]),
          value: soggetto?.abilitatoBackoffice ?? false,
          label: 'MGU_INCARDINAMENTO_MODAL_FORM_ABILITATO_BACKOFFICE',
          required: true,
          validationStatus: [ValidationStatus.ERROR.REQUIRED],
        }),
        notificaControlloAccessi: new AutocompleteInput({
          formGroupClass: ['mb-2'],
          size: '12|12|6|6|6',
          placeholder:
            'MGU_INCARDINAMENTO_MODAL_FORM_NOTIFICHE_ACCESSO_PLACEHOLDER',
          options: of([
            {
              value: true,
              label: this.i18n.translate('UTILS_ABILITATO'),
            },
            {
              value: false,
              label: this.i18n.translate('UTILS_DISABILITATO'),
            },
          ]),
          value: soggetto?.notificaControlloAccessi ?? false,
          label: 'MGU_INCARDINAMENTO_MODAL_FORM_NOTIFICHE_ACCESSO',
          required: true,
          validationStatus: [ValidationStatus.ERROR.REQUIRED],
        }),
        funzioneCodice: new AutocompleteInput({
          formGroupClass: ['mb-2'],
          size: '12|12|12|12|12',
          placeholder: 'MGU_INCARDINAMENTO_MODAL_FORM_FUNZIONE_PLACEHOLDER',
          options: this.organizzazioniService.getFuncs(),
          value: soggetto?.funzioneCodice,
          label: 'MGU_INCARDINAMENTO_MODAL_FORM_FUNZIONE',
          required: true,
          readonly: this.isHr,
          validationStatus: [ValidationStatus.ERROR.REQUIRED],
        }),
      }),
    };
  }
}
