<div class="row">
  <div class="col-12">
    <span
      class="
        label_underline
        d-block
        pb-2
        pt-5
        font-size-18
        color-dark-gray
        font-weight-bold
      "
    >
      {{ "MGU_USER_DETAIL_INCARICHI" | translate }}
    </span>
  </div>
  <div class="row w-100">
    <div class="col-12 pb-3">
      <agid-button
        *ngIf="!addDisabled"
        (click)="addIncarico()"
        class="float-right"
        size="small"
      >
        {{ "MGU_USER_DETAIL_BUTTONS_MANAGE_INCARICHI" | translate }}
      </agid-button>
    </div>
    <div class="col-12">
      <div
        class="row font-size-16"
        style="margin-right: -30px; margin-left: -18px"
      >
        <div class="table-responsive">
          <adsp-table
            [columns]="columnsIncarichi"
            [dataSource]="dataSourceIncarichi"
            [showPager]="true"
            class="adsp-table w-100"
          >
          </adsp-table>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #editTemplate let-row="row">
  <agid-icon
    *ngIf="!editDisabled"
    (click)="editIncarico(row)"
    [title]="'MGU_UTENTI_ORGANITATIONS_LIST_BUTTON_EDIT_TITLE' | translate"
    color="secondary"
    icon="it-pencil"
    size="small"
  ></agid-icon>
</ng-template>

<adsp-mgu-modal-incarico
  (closed)="onClose($event)"
  [incarico]="selectedIncarico"
  [uuidSoggettoFisico]="this.soggettoFisicoUuid"
  [open]="openDialog"
>
</adsp-mgu-modal-incarico>
