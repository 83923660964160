import { BaseInput, BaseInputConstructor } from './base-input';

export interface DividerConstructor extends BaseInputConstructor {
  marginTop?: number;
  marginBottom?: number;
  label: string;
}

export class DividerInput extends BaseInput {
  inputType = 'divider';
  label: string;
  marginTop: number;
  marginBottom: number;
  constructor(options: DividerConstructor) {
    super(options);

    this.marginTop = options.marginTop === undefined ? 2 : options.marginTop;
    this.marginBottom =
      options.marginBottom === undefined ? 2 : options.marginBottom;
    this.label = options.label;
  }
}
