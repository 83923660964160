import { ApiConfig, BackendConfig } from '@eng-ds/api-client';

declare const Adsp: any;

const apiGeneralPath = '/apigeneral/1.0';
const apiScrivaniaPath = '/apiscrivania/1.0';
const apiMguPath = '/apimgu/1.0';
const apiCip = '/apicip/1.0';

const backendApi: ApiConfig[] = [
  {
    name: 'getTranslate',
    method: 'GET',
    url:
      Adsp.configs.get('appHost') +
      '/general_lingua/{languageCode}/{module}.json',
  },
  {
    name: 'getRuoli',
    method: 'GET',
    url: apiMguPath + '/ruoli/list',
  },
  {
    name: 'getVerticali',
    method: 'GET',
    url: apiMguPath + '/verticali/list',
  },
  {
    name: 'getUtenti',
    method: 'GET',
    url: apiMguPath + '/utenti/list',
  },
  {
    name: 'upsertContatto',
    method: 'PATCH',
    url: apiMguPath + '/contatti/{uuid}',
  },
  {
    name: 'setContatto',
    method: 'POST',
    url: apiMguPath + '/contatti/{uuid}',
  },
  {
    name: 'getDettaglioRuolo',
    method: 'GET',
    url: apiMguPath + '/ruoli/{uuid}/dettaglio',
  },
  {
    name: 'deleteOrganization',
    method: 'DELETE',
    url: apiMguPath + '/organizzazione/{uuid}',
  },
  {
    name: 'getOrganizzazioni',
    method: 'GET',
    url: apiMguPath + '/organizzazione/list',
  },
  {
    name: 'getDettaglioOrganizzazione',
    method: 'GET',
    url: apiMguPath + '/organizzazione/{uuid}',
  },
  {
    name: 'getOrganigrammaOrganizzazione',
    method: 'GET',
    url: apiMguPath + '/organizzazione/{uuid}/organigramma',
  },
  {
    name: 'changeOrganizationHierarchy',
    method: 'POST',
    url: apiMguPath + '/organizzazione/{uuid}/associazioneOrgPadre',
  },
  {
    name: 'getOrganizzazioniFiglie',
    method: 'GET',
    url: apiMguPath + '/organizzazione/orgFiglieList',
  },
  {
    name: 'createOrganization',
    method: 'POST',
    url: apiMguPath + '/organizzazione/salvaDati',
  },
  {
    name: 'updateGeneralInfoOrganization',
    method: 'PATCH',
    url: apiMguPath + '/organizzazione/{uuid}/salvaDati',
  },
  {
    name: 'getCategories',
    method: 'GET',
    url: apiMguPath + '/config/staticList/categorieOrganizzazione',
  },
  {
    name: 'listTipoElencoValori',
    method: 'GET',
    url: apiScrivaniaPath + '/config/staticList/tipoElencoValori',
  },
  {
    name: 'getFunzioneIncardinamento',
    method: 'GET',
    url: apiMguPath + '/config/staticList/funzioneIncardinamento',
  },
  {
    name: 'exportUtenti',
    method: 'GET',
    url: apiMguPath + '/utenti/export',
  },
  {
    name: 'getUtente',
    method: 'GET',
    url: apiMguPath + '/utenti/{uuid}',
  },
  {
    name: 'getRuoliUtente',
    method: 'GET',
    url: apiMguPath + '/utenti/{uuid}/ruoli',
  },
  {
    name: 'associaRuoliUtente',
    method: 'POST',
    url: apiMguPath + '/utenti/ruoli',
  },
  {
    name: 'getUtentiOrganizzazione',
    method: 'GET',
    url: apiMguPath + '/utenti/organizzazione/{uuidOrg}',
  },
  {
    name: 'associateUsersToOrganization',
    method: 'POST',
    url: apiMguPath + '/organizzazione/{uuid}/associazioneUtenti',
  },
  {
    name: 'exportOrganizzazione',
    method: 'GET',
    url: apiMguPath + '/organizzazione/export',
  },
  {
    name: 'associaOrganizzazioniUtente',
    method: 'POST',
    url: apiMguPath + '/utenti/organizzazioni',
  },
  {
    name: 'updateOrganizzazioniUtente',
    method: 'PATCH',
    url: apiMguPath + '/utenti/{uuid}/organizzazione',
  },
  {
    name: 'getListDeleghe',
    method: 'GET',
    url: apiMguPath + '/delega/list',
  },
  {
    name: 'exportListDeleghe',
    method: 'GET',
    url: apiMguPath + '/delega/export',
  },
  {
    name: 'getDetailDelega',
    method: 'GET',
    url: apiMguPath + '/delega/{uuid}',
  },
  {
    name: 'getProcedimentiByDelega',
    method: 'GET',
    url: apiMguPath + '/delega/{uuid}/procedimenti',
  },
  {
    name: 'getStatiDelega',
    method: 'GET',
    url: apiMguPath + '/config/staticList/statoDelega',
  },
  {
    name: 'getTipiRichiedente',
    method: 'GET',
    url: apiMguPath + '/config/staticList/tipoRichiedenteDelega',
  },
  {
    name: 'updateStatoDelega',
    method: 'PATCH',
    url: apiMguPath + '/delega/{uuid}/stato',
  },
  {
    name: 'updateStatoProcedimento',
    method: 'PATCH',
    url: apiMguPath + '/delega/procedimento/{uuid}/stato',
  },
  {
    name: 'getTipologieDeleghe',
    method: 'GET',
    url: apiMguPath + '/config/staticList/tipoDelega',
  },
  {
    name: 'getAllegatiDelega',
    method: 'GET',
    url: apiMguPath + '/delega/{uuid}/documenti',
  },
  {
    name: 'downloadDelegaDocument',
    method: 'GET',
    url: apiMguPath + '/delega/documenti/downlod/{uuidDocumento}',
  },
  {
    name: 'getNazioni',
    method: 'GET',
    url: apiGeneralPath + '/config/nazioni',
  },
  {
    name: 'getProvince',
    method: 'GET',
    url: apiGeneralPath + '/config/province',
  },
  {
    name: 'getComuni',
    method: 'GET',
    url: apiGeneralPath + '/config/comuni/{uuid}',
  },
  {
    name: 'getProcedimenti',
    method: 'GET',
    url: apiCip + '/procedimenti',
  },
  {
    name: 'getIncarichi',
    method: 'GET',
    url: apiMguPath + '/incarichi/{uuidSoggettoFisico}',
  },
  {
    name: 'addIncarico',
    method: 'POST',
    url: apiMguPath + '/incarichi/{uuidSoggettoFisico}',
  },
  {
    name: 'editIncarico',
    method: 'PATCH',
    url: apiMguPath + '/incarichi/{uuid}',
  },
  {
    name: 'getProcedimentiAssociatiUtente',
    method: 'GET',
    url: apiMguPath + '/procedimentiruoli/{uuidUtente}',
  },
  {
    name: 'associaProcedimentiUtente',
    method: 'POST',
    url: apiMguPath + '/procedimentiruoli/assegnautente',
  },
  {
    name: 'updateProcedimentoUtente',
    method: 'PATCH',
    url: apiMguPath + '/procedimentiruoli/modifica',
  },
  {
    name: 'rimuoviAssociazioneProcedimentoUtente',
    method: 'DELETE',
    url: apiMguPath + '/procedimentiruoli/{uuidRelazione}',
  },
  {
    name: 'statiPubblicazione',
    method: 'GET',
    url: apiCip + '/config/staticList/statoPubblicazione',
  },
  {
    name: 'bloccaUtente',
    method: 'PATCH',
    url: apiMguPath + '/utenti/{uuid}/stato',
  },
  {
    name: 'resetPassword',
    method: 'POST',
    url: apiMguPath + '/utenti/{uuid}/resetpassword',
  },
  {
    name: 'statiSua',
    method: 'GET',
    url: apiMguPath + '/config/staticList/tipoStatoSua',
  },
  {
    name: 'validaUtente',
    method: 'PATCH',
    url: apiMguPath + '/utenti/{uuid}/changeStateSua',
  },
  {
    name: 'inserisciUtente',
    method: 'POST',
    url: apiMguPath + '/utenti/basicUser',
  },
  {
    name: 'getRuoliListBySoggettoFisico',
    method: 'GET',
    url: apiMguPath + '/verticali/{uuidSoggettoFisico}/ruoliList',
  },
  {
    name: 'creaAccountSua',
    method: 'POST',
    url: apiMguPath + '/utenti/{uuidSoggettoFisico}/creaAccountSUA',
  },
];

export const backendConfig: Omit<BackendConfig, 'getApiConfig' | 'prepareUrl'> =
  {
    baseUrl: Adsp.configs.get('baseApiUrl'),
    api: backendApi,
  };
