<div class="row">
  <div class="col-12">
    <span class="label_underline d-block pb-2 pt-5 font-size-18 color-dark-gray font-weight-bold">
      {{ "MGU_USER_DETAIL_USERNAME_SECTION_TITLE" | translate }}
    </span>
  </div>
  <div class="col-12 mt-3">
    <div
      class="row font-size-16 justify-content-between"
      style="margin-left: -18px"
    >
      <div class="col-sm-6 mt-3 col-md-4 border-right-grey">
        <span class="d-block" translate>{{ 'MGU_USER_DETAIL_USERNAME' }}</span>
        <span class="font-weight-bold">{{username || '---'}}</span>
      </div>

      <agid-button
        (click)="open()"
        *ngIf="!username"
        class="align-self-center pl-3 pb-3 pr-2"
        color="primary"
        size="small"
      >
        {{ "MGU_USER_DETAIL_USERNAME_BUTTON" | translate }}
      </agid-button>
    </div>
  </div>
</div>

<adsp-mgu-dialog-modifica-username
  (closedDialog)="onClose($event)"
  [open]="openDialog"
  [soggettoFisicoUuid]="soggettoFisicoUuid">
</adsp-mgu-dialog-modifica-username>
