import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { TableColumn } from '../../table/models/column.model';
import { formatDate } from '@angular/common';
import { I18nService } from '@eng-ds/translate';
import { UtentiService } from '../../../pages/utenti/services';
import { Incarico } from '../../models/incarico.model';
import { BaseComponent } from '../../../core/components';
import { SimpleDataSource } from '../../table/models/simple-data-source';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'adsp-mgu-sezione-incarichi',
  templateUrl: './sezione-incarichi.component.html',
  styleUrls: ['./sezione-incarichi.component.css'],
  providers: [UtentiService],
})
export class SezioneIncarichiComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  @ViewChild('editTemplate')
  editTemplate: TemplateRef<any>;

  @Input() editDisabled = false;
  @Input() addDisabled = false;
  @Input() soggettoFisicoUuid: string | null;
  columnsIncarichi: TableColumn[] = [];
  dataSourceIncarichi: SimpleDataSource<Incarico> = new SimpleDataSource({
    observable: () => this.incarichiList.asObservable(),
  });
  private incarichiList: BehaviorSubject<any[]> = new BehaviorSubject([]);
  openDialog = false;
  selectedIncarico: Incarico;

  constructor(protected i18n: I18nService, private service: UtentiService) {
    super(i18n);
  }

  ngOnInit(): void {
    this.service
      .getIncarichi(this.soggettoFisicoUuid)
      .pipe(
        catchError((err) => {
          return this._handleErrorMessage(err.error, true);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((list) => this.incarichiList.next(list));
  }

  addIncarico() {
    this.open();
  }

  ngAfterViewInit(): void {
    this.columnsIncarichi = [
      {
        width: 5,
        prop: '',
      },
      {
        prop: 'tipoIncarico',
        name: this.i18n.translate('MGU_USER_DETAIL_INCARICHI_TIPO_INCARICO'),
        width: 40,
        cellClass: 'align-middle font-weight-bold primary-color',
        pipe: {
          transform: (value) => value || '---',
        },
      },
      {
        prop: 'portoRiferimento',
        name: this.i18n.translate(
          'MGU_USER_DETAIL_INCARICHI_PORTO_RIFERIMENTO'
        ),
        width: 40,
        cellClass: 'align-middle font-weight-bold primary-color',
        pipe: {
          transform: (value) => value || '---',
        },
      },
      {
        prop: 'dataInizio',
        name: this.i18n.translate('MGU_USER_DETAIL_INCARICHI_DATE_TO'),
        width: 15,
        cellClass: 'align-middle',
        pipe: {
          transform: (value) =>
            value ? formatDate(value, 'dd/MM/yyyy', 'it-IT') : '---',
        },
      },
      {
        prop: 'dataFine',
        name: this.i18n.translate('MGU_USER_DETAIL_INCARICHI_DATE_END'),
        width: 15,
        cellClass: 'align-middle',
        pipe: {
          transform: (value) =>
            value ? formatDate(value, 'dd/MM/yyyy', 'it-IT') : '---',
        },
      },
      {
        cellTemplate: this.editTemplate,
        width: 5,
        cellClass: 'align-middle cursor-pointer',
      },
    ];
  }

  editIncarico(row: Incarico) {
    this.selectedIncarico = row;
    this.open();
  }

  onClose(incarico?: Incarico) {
    this.openDialog = false;
    if (incarico && incarico.uuid) {
      this.service
        .getIncarichi(this.soggettoFisicoUuid)
        .pipe(
          catchError((err) => {
            return this._handleErrorMessage(err.error, true);
          }),
          takeUntil(this.destroy$)
        )
        .subscribe((list) => this.incarichiList.next(list));
    }
    delete this.selectedIncarico;
  }

  private open() {
    this.openDialog = true;
  }
}
