import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { I18nService } from '@eng-ds/translate';
import { throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';

import { AutoUnsubscribe, BaseComponent } from 'src/app/core/components';
import { UtentiService } from 'src/app/pages/utenti/services';

import { OrganizzazioniService } from '../../../pages/organizzazioni/core/services';
import { TipoStatoSua, Utente } from '../../../pages/utenti/model';
declare const AgidWebComponents: any;
@Component({
  selector: 'adsp-mgu-modale-valida-sua',
  templateUrl: './modale-valida-sua.component.html',
  styleUrls: ['./modale-valida-sua.component.css'],
  providers: [OrganizzazioniService],
})
export class ModaleValidaSuaComponent extends BaseComponent implements OnInit {
  TIPO_STATO_SUA = TipoStatoSua;
  @Input() open = false;
  errorMessage = null;
  utenteSel = null;
  @Input() set elementSelected(value: Utente) {
    this.utenteSel = value;
  }
  @Output() refresh: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();
  constructor(protected i18n: I18nService, private service: UtentiService) {
    super(i18n);
  }

  ngOnInit(): void {}

  closeModale(): void {
    this.open = false;
    this.close.emit();
  }

  validaSUA(statoSua: TipoStatoSua) {
    this.errorMessage = null;
    this.service
      .validaUtente(this.utenteSel, statoSua)
      .pipe(
        catchError((err) => {
          this.errorMessage = this._controlTranslate(err.error);
          return throwError(err);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        statoSua === 'APPROVATO'
          ? AgidWebComponents.notifications.success(
              this.i18n.translate(
                'MGU_UTENTE_LIST_TOASTR_VALIDA_SUCCESS_TITLE'
              ),
              this.i18n.translate(
                'MGU_UTENTE_LIST_TOASTR_VALIDA_APPROVATO_SUCCESS_TEXT'
              )
            )
          : AgidWebComponents.notifications.success(
              this.i18n.translate(
                'MGU_UTENTE_LIST_TOASTR_VALIDA_SUCCESS_TITLE'
              ),
              this.i18n.translate(
                'MGU_UTENTE_LIST_TOASTR_VALIDA_NEGATO_SUCCESS_TEXT'
              )
            );

        this.closeModale();
        this.refresh.emit(statoSua);
      });
  }
}
