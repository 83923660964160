import { Injectable } from '@angular/core';
import { ApiClient } from '@eng-ds/api-client';
import { I18nService } from '@eng-ds/translate';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SelectOption } from 'src/app/shared/form';
import { ApiFiltersRequest, ApiPageableResponse } from '../backend';

@Injectable()
export class UtilityService {
  constructor(private apiClient: ApiClient, private i18n: I18nService) {}

  getNazioni(): Observable<SelectOption<string, string>[]> {
    return this.apiClient.request<SelectOption<string, string>[]>('getNazioni');
  }

  getProvince(uuid?: string): Observable<SelectOption<string, string>[]> {
    const queryParams: any = {};

    if (uuid) {
      queryParams.uuid = uuid;
    }

    return this.apiClient.request<SelectOption<string, string>[]>(
      'getProvince',
      null,
      queryParams,
      null
    );
  }

  getComuni(uuid: string): Observable<SelectOption<string, string>[]> {
    return this.apiClient.request<SelectOption<string, string>[]>(
      'getComuni',
      null,
      null,
      { uuid }
    );
  }

  getStatoPubblProcedimento(): Observable<any> {
    let index = 0;
    return this.apiClient.request<Observable<any>>('statiPubblicazione').pipe(
      map((value) => {
        const _stato: any = [];
        value.forEach((element) => {
          if (element !== '') {
            _stato[index] = element;
          }
          index++;
        });
        const stato: SelectOption[] = [];
        _stato?.forEach((elm) => {
          stato.push({
            label: this.i18n.translate(
              'MGU_PROCEDIMENTO_LIST_COLUMNS_STATO_' + elm
            ),
            value: elm,
          });
        });
        return stato;
      })
    );
  }

  getProcedimenti(
    filters: ApiFiltersRequest
  ): Observable<ApiPageableResponse<any>> {
    return this.apiClient.request('getProcedimenti', null, filters.toServer());
  }
}
