import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UtilityService } from 'src/app/core/services/utility.service';
import {
  AutocompleteInput,
  BaseInput,
  Form,
  NopInput,
  TextInput,
  ValidationStatus,
} from 'src/app/shared/form';
import {
  ORGANIZATION,
  Organizzazione,
  OrganizzazionePadre,
  Utente,
} from '../models';
import { OrganizzazioniService } from './organizzazioni.service';

@Injectable()
export class OrganizzazioneStoreService {
  formChartOrganizations: Form;
  formGeneralDataOrganization: Form;
  formContactsDataOrganization: Form;
  private uuid: string;
  private chartTabSubmit: Subject<boolean> = new Subject();
  private userTabSubmit: Subject<boolean> = new Subject();
  private generalDataTabSubmit: Subject<boolean> = new Subject();
  private disableTabs: BehaviorSubject<boolean> = new BehaviorSubject(true);
  private currentTab: BehaviorSubject<number> = new BehaviorSubject(0);
  private preselectedUsers: BehaviorSubject<Utente[]> = new BehaviorSubject([]);
  private finalSelectedUsers: BehaviorSubject<Utente[]> = new BehaviorSubject(
    []
  );

  private _validationPattern = {
    partitaIva: '^[0-9]{11}$',
    codiceFiscale:
      '(^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$)|(^[0-9]{11}$)',
  };

  constructor(
    private service: OrganizzazioniService,
    private utilityService: UtilityService
  ) {}

  get chartTabSubmit$(): Observable<boolean> {
    return this.chartTabSubmit.asObservable();
  }

  get userTabSubmit$(): Observable<boolean> {
    return this.userTabSubmit.asObservable();
  }

  get generalDataTabSubmit$(): Observable<boolean> {
    return this.generalDataTabSubmit.asObservable();
  }

  get currentTab$(): Observable<number> {
    return this.currentTab.asObservable();
  }

  get disableTabs$(): Observable<boolean> {
    return this.disableTabs.asObservable();
  }

  get preselectedUsers$(): Observable<Utente[]> {
    return this.preselectedUsers.asObservable();
  }

  get finalSelectedUsers$(): Observable<Utente[]> {
    return this.finalSelectedUsers.asObservable();
  }

  initForms(
    organization?: Organizzazione,
    fathers: OrganizzazionePadre[] = []
  ): void {
    this._initChartOrganizationsForm(this.sortByMaxLevel(fathers));
    this._initGeneralDataOrganizationForm(organization);
    this._initContactsDataOrganizationForm(organization);
  }

  resetAll(): void {
    this.setUuid(undefined);
    this.currentTab.next(0);
    this.disableTabs.next(true);
    this.preselectedUsers.next([]);
    this.finalSelectedUsers.next([]);
  }

  setChartTabSubmit(submit: boolean): void {
    this.chartTabSubmit.next(submit);
  }

  setUserTabSubmit(submit: boolean): void {
    this.userTabSubmit.next(submit);
  }

  setGeneralDataTabSubmit(submit: boolean): void {
    this.generalDataTabSubmit.next(submit);
  }

  setCurrentTab(index: number): void {
    this.currentTab.next(index);
  }

  setDisableTabs(disable: boolean): void {
    this.disableTabs.next(disable);
  }

  setPreselectedUsers(users: Utente[]): void {
    if (users) {
      this.preselectedUsers.next([...users]);
    }
  }

  setFinalUsers(users: Utente[]): void {
    if (users) {
      this.finalSelectedUsers.next([...users]);
    }
  }

  removeFinalSelectedUsers(user: Utente): void {
    this.finalSelectedUsers.next(
      [...this.finalSelectedUsers.value].filter(
        (_user) => _user.uuid !== user.uuid
      )
    );
  }

  setUuid(uuid: string): void {
    this.uuid = uuid;
  }

  getUuid(): string {
    return this.uuid;
  }

  hasUuid(): boolean {
    return this.uuid !== undefined;
  }

  private _initChartOrganizationsForm(
    fathers: OrganizzazionePadre[] = []
  ): void {
    this.formChartOrganizations = new Form({
      header: { show: false },
      skipDestroy: true,
      controls: this.getChartOrganizationsFields(fathers),
    });
  }

  private getChartOrganizationsFields(fathers: OrganizzazionePadre[]): {
    [key: string]: BaseInput<any>;
  } {
    const controls: { [key: string]: any } = {};

    controls.no_uuid = new AutocompleteInput({
      size: '12|12|6|4|4',
      options: this.service.getOrganizationChildren(),
      value: fathers[0]?.uuid || ORGANIZATION.NO_ORGANIZATION,
      valueChange: (value) => {
        this.onValueChangeOrganization(
          value,
          this.formChartOrganizations.get('no_uuid')
        );
      },
    });

    fathers.forEach((elm, index) => {
      if (index < fathers.length - 1) {
        controls[elm.uuid] = this.getNewOrganizationControl(
          elm.uuid,
          fathers[index + 1].uuid
        );
      } else {
        controls[elm.uuid] = this.getNewOrganizationControl(elm.uuid);
      }
    });

    return controls;
  }

  private getNewOrganizationControl(
    uuid: string,
    value?: string
  ): BaseInput<any> {
    return new AutocompleteInput({
      value: value || ORGANIZATION.NO_ORGANIZATION,
      size: '12|12|6|4|4',
      options: this.service.getOrganizationChildren(uuid),
      valueChange: (_value: string) => {
        this.onValueChangeOrganization(
          _value,
          this.formChartOrganizations.get(uuid)
        );
      },
    });
  }

  private onValueChangeOrganization(
    uuid: string,
    control: BaseInput<any>
  ): void {
    if (
      control?.index <
      this.formChartOrganizations.findMaxIndex(
        this.formChartOrganizations.controls
      )
    ) {
      this.removeAllControlsAfter(control);
    }
    if (uuid !== ORGANIZATION.NO_ORGANIZATION) {
      this.formChartOrganizations.addControl(
        uuid,
        this.getNewOrganizationControl(uuid)
      );
    }
  }

  private removeAllControlsAfter(control: BaseInput<any>): void {
    const controls = this.formChartOrganizations.controls;
    for (const name in controls) {
      if (controls[name].index > control.index) {
        this.formChartOrganizations.removeControl(name);
      }
    }
  }

  private _initGeneralDataOrganizationForm(organization: Organizzazione): void {
    this.formGeneralDataOrganization = new Form({
      header: {
        show: false,
      },
      skipDestroy: true,
      controls: this.getGeneralDataFields(organization),
    });
  }

  private _initContactsDataOrganizationForm(
    organization: Organizzazione
  ): void {
    this.formContactsDataOrganization = new Form({
      header: {
        show: false,
      },
      skipDestroy: true,
      controls: this.getContactsDataFields(organization),
    });
  }

  private getGeneralDataFields(organization: Organizzazione): {
    [key: string]: BaseInput<any>;
  } {
    return {
      denominazione: new TextInput({
        type: 'text',
        size: '12|12|6|4|4',
        required: true,
        value: organization?.denominazione,
        validationStatus: [ValidationStatus.ERROR.REQUIRED],
        label: 'MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_GENERAL_DATA_NAME_LABEL',
        placeholder:
          'MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_GENERAL_DATA_NAME_PLACEHOLDER',
      }),
      categoria: new AutocompleteInput({
        options: this.service.getCategories(),
        size: '12|12|6|4|4',
        required: true,
        validationStatus: [ValidationStatus.ERROR.REQUIRED],
        value: organization?.categoria,
        label:
          'MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_GENERAL_DATA_CATEGORY_LABEL',
        placeholder:
          'MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_GENERAL_DATA_CATEGORY_PLACEHOLDER',
      }),
      nop: new NopInput({
        size: '12|12|6|4|4',
      }),
      codiceFiscale: new TextInput({
        type: 'text',
        size: '12|12|6|4|4',
        pattern: this._validationPattern.codiceFiscale,
        validationStatus: [
          ValidationStatus.WARNING((control) => control.hasError('pattern'), {
            text: 'MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_GENERAL_DATA_FISCAL_CODE_ERROR',
          }),
        ],
        value: organization?.codiceFiscale,
        label:
          'MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_GENERAL_DATA_FISCAL_CODE_LABEL',
        placeholder:
          'MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_GENERAL_DATA_FISCAL_CODE_PLACEHOLDER',
      }),
      codiceUfficio: new TextInput({
        type: 'text',
        size: '12|12|6|4|4',
        required: true,
        validationStatus: [ValidationStatus.ERROR.REQUIRED],
        value: organization?.codiceUfficio,
        label:
          'MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_GENERAL_DATA_OFFICE_CODE_LABEL',
        placeholder:
          'MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_GENERAL_DATA_OFFICE_CODE_PLACEHOLDER',
      }),
      partitaIva: new TextInput({
        type: 'text',
        size: '12|12|6|4|4',
        value: organization?.partitaIva,
        pattern: this._validationPattern.partitaIva,
        validationStatus: [
          ValidationStatus.WARNING((control) => control.hasError('pattern'), {
            text: 'MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_GENERAL_DATA_PIVA_ERROR',
          }),
        ],
        label: 'MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_GENERAL_DATA_PIVA_LABEL',
        placeholder:
          'MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_GENERAL_DATA_PIVA_PLACEHOLDER',
      }),
    };
  }

  private getContactsDataFields(organization: Organizzazione): {
    [key: string]: BaseInput<any>;
  } {
    return {
      indirizzo: new TextInput({
        type: 'text',
        size: '12|12|6|4|4',
        required: true,
        validationStatus: [ValidationStatus.ERROR.REQUIRED],
        value: organization?.indirizzo,
        label:
          'MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_CONTACTS_DATA_ADDRESS_LABEL',
        placeholder:
          'MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_CONTACTS_DATA_ADDRESS_PLACEHOLDER',
      }),
      cap: new TextInput({
        type: 'text',
        size: '12|12|6|4|4',
        value: organization?.cap,
        required: true,
        pattern: '[0-9]{5}',
        validationStatus: [
          ValidationStatus.ERROR.REQUIRED,
          ValidationStatus.WARNING((control) => control.hasError('pattern'), {
            text: 'MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_GENERAL_DATA_CAP_ERROR',
          }),
        ],
        label: 'MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_CONTACTS_DATA_CAP_LABEL',
        placeholder:
          'MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_CONTACTS_DATA_CAP_PLACEHOLDER',
      }),
      nop: new NopInput({
        size: '12|12|6|4|4',
      }),

      provinciaUuid: new AutocompleteInput({
        size: '12|12|6|4|4',
        value: organization?.provinciaUuid,
        required: true,
        validationStatus: [ValidationStatus.ERROR.REQUIRED],
        label:
          'MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_CONTACTS_DATA_PROVINCE_LABEL',
        placeholder:
          'MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_CONTACTS_DATA_PROVINCE_PLACEHOLDER',
        options: this.utilityService.getProvince().pipe(
          tap(() => {
            const value =
              this.formContactsDataOrganization.get('provinciaUuid').value;
            if (value) {
              this._setCitiesOptions('comuneUuid', value);
            }
          })
        ),
        valueChange: (value: string) => {
          if (value) {
            this.formContactsDataOrganization.get('comuneUuid').reset();
            this._setCitiesOptions('comuneUuid', value);
          }
        },
      }),

      comuneUuid: new AutocompleteInput({
        size: '12|12|6|4|4',
        value: organization?.comuneUuid,
        required: true,
        validationStatus: [ValidationStatus.ERROR.REQUIRED],
        label: 'MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_CONTACTS_DATA_CITY_LABEL',
        placeholder:
          'MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_CONTACTS_DATA_CITY_PLACEHOLDER',
        options: of([]),
      }),
      nop_2: new NopInput({
        size: '12|12|6|4|4',
      }),
      telefono: new TextInput({
        type: 'tel',
        size: '12|12|6|4|4',
        pattern: '^([+|0][0-9]{1,3})?[0-9]{3,14}$',
        required: true,
        validationStatus: [
          ValidationStatus.ERROR.REQUIRED,
          ValidationStatus.WARNING((control) => control.hasError('pattern'), {
            text: 'MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_CONTACTS_DATA_PHONE_ERROR',
          }),
        ],
        value: organization?.telefono,
        label:
          'MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_CONTACTS_DATA_PHONE_LABEL',
        placeholder:
          'MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_CONTACTS_DATA_PHONE_PLACEHOLDER',
      }),
      cellulare: new TextInput({
        type: 'tel',
        size: '12|12|6|4|4',
        value: organization?.cellulare,
        pattern: '^([+|0][0-9]{1,3})?[0-9]{3,14}$',
        validationStatus: [
          ValidationStatus.WARNING((control) => control.hasError('pattern'), {
            text: 'MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_CONTACTS_DATA_PHONE_ERROR',
          }),
        ],
        label:
          'MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_CONTACTS_DATA_MOBILE_PHONE_LABEL',
        placeholder:
          'MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_CONTACTS_DATA_MOBILE_PHONE_PLACEHOLDER',
      }),
      email: new TextInput({
        type: 'email',
        size: '12|12|6|4|4',
        value: organization?.email,
        validationStatus: [
          ValidationStatus.WARNING((control) => control.hasError('pattern'), {
            text: 'MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_GENERAL_DATA_EMAIL_ERROR',
          }),
        ],
        pattern: '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$',
        label:
          'MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_CONTACTS_DATA_EMAIL_LABEL',
        placeholder:
          'MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_CONTACTS_DATA_EMAIL_PLACEHOLDER',
      }),
      pec: new TextInput({
        type: 'email',
        size: '12|12|6|4|4',
        value: organization?.pec,
        validationStatus: [
          ValidationStatus.WARNING((control) => control.hasError('pattern'), {
            text: 'MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_GENERAL_DATA_EMAIL_ERROR',
          }),
        ],
        pattern: '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$',
        label: 'MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_CONTACTS_DATA_PEC_LABEL',
        placeholder:
          'MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_CONTACTS_DATA_PEC_PLACEHOLDER',
      }),
      codiceNodoPagamenti: new TextInput({
        type: 'text',
        size: '12|12|6|4|4',
        value: organization?.codNodoPagamenti,
        label:
          'MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_CONTACTS_DATA_PAYMENT_NODE_LABEL',
        placeholder:
          'MGU_ORGANITATIONS_NEW_TABS_DATA_FORMS_CONTACTS_DATA_PAYMENT_NODE_PLACEHOLDER',
      }),
    };
  }

  private _setCitiesOptions(cityFieldName: string, provinceUuid: string) {
    (
      this.formContactsDataOrganization.get(cityFieldName) as AutocompleteInput
    ).setOptions(this.utilityService.getComuni(provinceUuid));
  }

  private sortByMaxLevel(array: OrganizzazionePadre[]): OrganizzazionePadre[] {
    return array.sort((orgA, orgB) => orgA.livello - orgB.livello);
  }
}
