<div class="row" style="padding: 1rem 0;">
  <div class="col-12">
    <div class="row">
      <div
        *ngIf="file || control.placeholder"
        class="col-md-8 col-sm-12 d-flex align-items-center"
      >
        <span
          [ngClass]="
            file ? 'color-blue font-weight-bold' : 'color-black font-size-14'
          "
        >
          {{ file ? file.name : (control.placeholder | translate) }}
        </span>
      </div>
      <div class="col-sm-12 col-md-4 text-sm-center text-md-right">
        <button
          type="button"
          class="btn btn-sm btn-primary"
          (click)="fileTag.click()"
        >
          {{
            file
              ? ("UTILS_REMOVE" | translate)
              : (control.buttonPlaceholder | translate)
          }}
        </button>
        <input #fileTag type="file" class="inputfile" />
      </div>
    </div>
  </div>
</div>
