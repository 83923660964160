import { Component, Input } from '@angular/core';

import { DateInput } from '../../../models/inputs/date-input';
import { BaseInputComponent } from '../base-input/base-input.component';

@Component({
  selector: 'app-date-input',
  template: `<input
    [id]="id"
    class="custom-input shape-rectangle size-large nb-transition input-full-width"
    type="date"
    [formControl]="control"
    [appColorStatus]="control.colorStatus"
    maxlength="10"
  />`,
})
export class DateInputComponent extends BaseInputComponent {
  @Input() control: DateInput;
}
