import { Injectable } from '@angular/core';
import { ApiClient } from '@eng-ds/api-client';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiFiltersRequest } from 'src/app/core/backend';

import { Form, SelectOption } from '../form/models';
import { TableColumn } from '../table/models/column.model';

@Injectable({
  providedIn: 'root',
})
export class FunzioniIncardinamentoService {
  columns: TableColumn[] = [];
  filterForm: Form;

  constructor(
    private apiClient: ApiClient
  ) {}

  getFunzioneIncardinamento(
    filters?: ApiFiltersRequest
  ): Observable<SelectOption[]> {
    return this.apiClient.request('getFunzioneIncardinamento', null, filters?.toServer()).pipe(
      map((value: any) => {
        return value;
      })
    );
  }
}
