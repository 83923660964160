import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { TranslateModule } from '@eng-ds/translate';

import {
  DatiDipendenteComponent,
  EmptyRouteComponent,
  ModaleIncardinamentoComponent,
  ModaleRuoloUtenteComponent,
  ModaleSoggettoFisicoComponent,
  AssociaUsernameBoComponent,
} from './components';
import {
  ClickStopPropagationDirective,
  ColorStatusDirective,
  PopoverHostDirective,
} from './directives';
import { FormModule } from './form';
import { AssetUrlPipe } from './pipes';
import { SezioneIncarichiComponent } from './components/sezione-incarichi/sezione-incarichi.component';
import { TableModule } from './table/table.module';
import { ModalIncaricoComponent } from './components/sezione-incarichi/modal-incarico/modal-incarico.component';
import { DialogAssociazioneUsernameComponent } from './components/modifica-username-bo/dialog-associazione-username/dialog-associazione-username.component';
import { ModaleValidaSuaComponent } from './components/modale-valida-sua/modale-valida-sua.component';

@NgModule({
  imports: [CommonModule, FormModule, TranslateModule.forChild(), TableModule],
  declarations: [
    AssetUrlPipe,
    EmptyRouteComponent,
    ModaleIncardinamentoComponent,
    ModaleRuoloUtenteComponent,
    ModaleValidaSuaComponent,
    ColorStatusDirective,
    ClickStopPropagationDirective,
    PopoverHostDirective,
    ModaleSoggettoFisicoComponent,
    AssociaUsernameBoComponent,
    DatiDipendenteComponent,
    SezioneIncarichiComponent,
    ModalIncaricoComponent,
    ModaleValidaSuaComponent,
    DialogAssociazioneUsernameComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [
    CommonModule,
    AssetUrlPipe,
    FormModule,
    ModaleIncardinamentoComponent,
    ModaleSoggettoFisicoComponent,
    ModaleValidaSuaComponent,
    ModaleRuoloUtenteComponent,
    ColorStatusDirective,
    PopoverHostDirective,
    ClickStopPropagationDirective,
    TranslateModule,
    AssociaUsernameBoComponent,
    DatiDipendenteComponent,
    ModaleValidaSuaComponent,
    SezioneIncarichiComponent,
  ],
})
export class SharedModule {}
