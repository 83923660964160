<agid-modal
  (closed)="close(null)"
  [open]="open"
  class="dialog"
  position="center"
  size="large"
>
  <agid-modal-header class="pb-4 bordo align-items-baseline" close-icon="true">
    <span class="modal-title">
      {{ "MGU_INCARICO_MODAL_TITLE" | translate }}
    </span>
  </agid-modal-header>

  <agid-modal-body>
    <div class="container-fluid font-size-16">
      <div class="row m-0">
        <div *ngIf="errorMessage" class="alert alert-danger" role="alert">
          {{ errorMessage }}
        </div>
        <div class="col-12 m-auto riga">
          <div class="form-group p-2 m-0">
            <app-form *ngIf="formGroup" [form]="formGroup"></app-form>
          </div>
        </div>
      </div>
    </div>
  </agid-modal-body>

  <agid-modal-footer>
    <agid-button
      (click)="save()"
      [disabled]="!formGroup?.valid"
      class="m-auto"
      primary
    >
      {{ "FORM_SAVE" | translate }}
    </agid-button>
  </agid-modal-footer>
</agid-modal>
