import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { I18nService } from '@eng-ds/translate';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { Organizzazione, Utente } from '../models';
import { OrganizzazioneStoreService } from './organizzazione-store.service';
import { OrganizzazioniService } from './organizzazioni.service';

declare const AgidWebComponents: any;

@Injectable()
export class OrganizzazioneResolver implements Resolve<Organizzazione> {
  constructor(
    private i18n: I18nService,
    private service: OrganizzazioniService,
    private storeService: OrganizzazioneStoreService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Organizzazione> {
    return this.service
      .getDettaglioOrganizzazione(route.paramMap.get('id'))
      .pipe(
        catchError((err) => {
          AgidWebComponents.notifications.error(
            this.i18n.translate('MGU_ORGANITATIONS_DETAIL_FETCH_ERROR_TITLE'),
            this.i18n.translate('MGU_ORGANITATIONS_DETAIL_FETCH_ERROR_TEXT')
          );
          return throwError(err);
        }),
        tap((organization) => {
          // this.storeService.setParentOrganizations(organization.strutturaPadri);
          this.storeService.initForms(
            organization,
            organization.strutturaPadri
          ),
            this.storeService.setUuid(organization.uuid);
        }),
        switchMap((organization: Organizzazione) => {
          return this.service.getUtentiOrganizzazione(organization.uuid).pipe(
            catchError(() => of(null)),
            tap((resp) => {
              if (resp) {
                resp.content.map((user: Utente) => user.incardinamento.uuid = user.uuid);
                this.storeService.setFinalUsers(resp.content);
              }
            }),
            map(() => organization)
          );
        })
      );
  }
}
